import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useHistory } from "react-router";
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'
import { HalfMalf }
    from 'react-spinner-animated';

const cartFromLocalStorage = JSON.parse(localStorage.getItem("cart") || "[]")

const Pembayaran = () => {
    const url = "https://lbw-edunesia.com/api-lbw/public/";
    // const url = "http://localhost:8000/";
    const [paket, setPaket] = useState(cartFromLocalStorage);
    const [isPending, setIsPending] = useState(true);
    let [user, setUser] = useState([]);
    const [rekomendasi, setRekomendasi] = useState(null);
    let [total, setTotal] = useState(0);
    const history = useHistory();

    // get date
    const current = new Date();
    const date = `${current.getDate()}-${current.getMonth() + 1}-${current.getFullYear()}`;
    // add cart
    function addCart(idModule, namaModule, image, idPaket, namaPaket, ketPaket, harga, durasi) {
        let cartBaru = {
            "idModule": idModule,
            "namaModule": namaModule,
            "image": image,
            "idPaket": idPaket,
            "namaPaket": namaPaket,
            "ket": ketPaket,
            "harga": harga,
            "durasi": durasi,
        }

        paket.push(cartBaru);
        let cartString = JSON.stringify(paket);
        localStorage.setItem('cart', cartString);
        window.location.reload()
    }
    // delete cart
    function deleteCart(id) {
        let harga = [];
        const filter = paket.filter((item, index) => {
            return item.idPaket !== id
        })
        filter.map((item) => {
            harga.push(item.harga)
        })
        setPaket(filter)
        const parsed = JSON.stringify(filter)
        localStorage.setItem('cart', parsed)
        // hitung total
        let total = 0;
        harga.forEach(element => {
            setTotal(total += element)
        });
        // fetch ulang rekomendasi
        //fetch module from Rest API
        axios.get(url + 'api/paket')
            .then((response) => {
                // get local storage
                let cart = JSON.parse(localStorage.getItem('cart'))
                //set response user to data
                let data = response.data.data
                // filter rekomendasi berdasarkan localStorage
                cart.map(dataCart => {
                    data.map(dataRek => {
                        // bandingkan value id cart dan data api
                        if (dataCart.idPaket === dataRek.id) {
                            // ambil index bersangkutan
                            let index = data.indexOf(dataRek)
                            // splice data/hapus
                            data.splice(index, 1)
                        }
                    })
                })
                setRekomendasi(data)
                setIsPending(false)
            }).catch(err => console.log(err))
    }
    // order
    async function order(e) {
        e.preventDefault();
        let pakets = []
        paket.map(item => {
            pakets.push(
                {
                    id_module: item.idModule,
                    nama_paket: item.namaPaket,
                    ket_paket: item.ket,
                    durasi_paket: item.durasi,
                    harga_paket: item.harga,
                }
            )
        })
        // get date
        const current2 = new Date();
        const date2 = `${current2.getFullYear()}-${current2.getMonth() + 1}-${current2.getDate()}`;
        let orderData = {
            id_user: user.id,
            tanggal: date2,
            voucher: "",
            potongan: 0,
            gross_amount: total,
            status: "checkout",
            transaction_details: pakets
        }
        // post data
        await axios.post(url + 'api/order', orderData)
            .then((response) => {
                console.log("sukses")
                localStorage.removeItem("cart");
                history.push(`/checkout`);
                window.location.reload();
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        //load persisted cart into state if it exists
        if (localStorage.getItem('cart')) {
            var localCart = JSON.parse(localStorage.getItem('cart'));
            setPaket(localCart)
            setIsPending(false)
            // hitung total
            let total = 0;
            localCart.forEach(element => {
                setTotal(total += parseInt(element.harga))
            });
        }

        //fetch module from Rest API
        axios.get(url + 'api/paket')
            .then((response) => {
                // get local storage
                let cart = JSON.parse(localStorage.getItem('cart'))
                //set response user to data
                let data = response.data.data
                // filter rekomendasi berdasarkan localStorage
                cart.map(dataCart => {
                    data.map(dataRek => {
                        // bandingkan value id cart dan data api
                        if (dataCart.idPaket === dataRek.id) {
                            // ambil index bersangkutan
                            let index = data.indexOf(dataRek)
                            // splice data/hapus
                            data.splice(index, 1)
                        }
                    })
                })
                setRekomendasi(data)
                setIsPending(false)
                // setIsFetch(true)
            }).catch(err => console.log(err))

        //set axios header dengan type Authorization + Bearer token
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
        //fetch user from Rest API
        axios.get(url + 'api/user')
            .then((response) => {
                //set response user to state
                if(response.data.status == "Token is Expired"){
                    localStorage.removeItem("token");
                    history.push('/login');
                }
                setUser(response.data.user);
            }).catch(err => console.log(err))
    }, [])

    return (
        <React.Fragment>
            <Navbar />
            <div className="inner-banner inner-bg7">
                <div className="container">
                    <div className="inner-title text-center">
                        <h3>Pembayaran</h3>
                    </div>
                </div>
            </div>
            {isPending && <HalfMalf text={"Loading..."} bgColor={"#f8f9fa"} center={true} width={"150px"} height={"150px"} />}
            {!isPending &&
                paket.length == 0 ?
                <h1 className="text-center mt-5 mb-5">Keranjang Kosong</h1>
                :
                <div className="contact-wrap">
                    <div className="contact-wrap-form">
                        <div className="row">
                            <div className="col-12">
                                <h3 className="pembayaran-area">Detail Pembelian</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-7 text-left">
                                <div className="row">
                                    <div className="col-12">
                                        <h3 className="pembayaran-area">Rincian Pembelian</h3>
                                    </div>
                                </div>
                                {paket.map((item) => {
                                    return (
                                        <div key={item.idPaket}>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <img src={item.image} alt="Module Images" width="250" height="210" />
                                                    <h3>{item.namaModule}</h3>
                                                </div>
                                                <div className="col-md-6">
                                                    <p>{item.namaPaket}</p>
                                                    <p>Harga: Rp. {parseInt(item.harga).toLocaleString(undefined, {})}</p>
                                                    <p>Masa Aktif: {item.durasi} Bulan</p>
                                                    <p>Tanggal Pembelian: {date}</p>
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                    )
                                })}


                                <div className="row">
                                    {rekomendasi &&
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div class="form-group">
                                                    <label for="exampleFormControlSelect1">Paket Belajar Lainnya:</label>
                                                    <select class="form-control" id="exampleFormControlSelect1">
                                                        <option value="0">Pilihan Paket Belajar</option>
                                                        {rekomendasi.map((rek) => {
                                                            return <option key={rek.id} onClick={() => { addCart(rek.module_data.id, rek.module_data.nama, rek.module_data.image, rek.id, rek.nama, rek.ket, rek.harga, rek.durasi) }}>{rek.module_data.nama} - {rek.nama} (Rp. {parseInt(rek.harga).toLocaleString(undefined, {})})</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                    }

                                </div>
                            </div>
                            <div className="col-md-5 text-left">
                                <div class="card" style={{ width: '18rem' }}>
                                    <div class="card-header">
                                        Keranjang
                                    </div>
                                    <ul class="list-group list-group-flush">
                                        {paket.map((item) => {

                                            return (
                                                <li class="list-group-item" key={item.idPaket}>
                                                    <div className="row">
                                                        <div className="col-md-10">
                                                            <h5>{item.namaModule}</h5>
                                                            <p>{item.namaPaket} - {item.durasi} Bulan</p>
                                                            <span>Rp. {parseInt(item.harga).toLocaleString(undefined, {})}</span>
                                                        </div>
                                                        {paket.length <= 1 ?
                                                            ""
                                                            :
                                                            <div className="col-md-2">
                                                                <span onClick={() => { deleteCart(item.idPaket) }} style={{ cursor: 'pointer' }}>x</span>
                                                            </div>
                                                        }
                                                    </div>
                                                </li>
                                            )
                                        })}
                                        <li class="list-group-item">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    Total
                                                </div>
                                                <div className="col-md-8">
                                                    <b>Rp. {parseInt(total).toLocaleString(undefined, {})}</b>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-10"></div>
                            <div className="col-md-2">
                                <a href="#" className="default-btn btn-sm font-semibig" onClick={order}>Checkout</a>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Footer />
        </React.Fragment>
    );
}

export default Pembayaran;