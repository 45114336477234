import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import { AuthContext } from '../../AuthContext';
import { UserContext } from '../../UserContext';
import { Link } from 'react-router-dom';
//import axios
import axios from 'axios';
import Footer from '../../components/Header'
import Navbar from '../../components/Navbar'
import { HalfMalf }
    from 'react-spinner-animated';

const ChangePassword = () => {
    const url = "https://lbw-edunesia.com/api-lbw/public/";
    // const url = "http://localhost:8000/";
    // authcontext in app
    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext)
    const { user, setUser } = useContext(UserContext)
    //define state
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPassword2, setNewPassword2] = useState("");
    const [isPending, setIsPending] = useState(true);
    const [auth, setAuth] = useState([]);
    //define state validation
    const [validation, setValidation] = useState([]);

    //define history
    const history = useHistory();

    // cek jika sudah login maka redirect ke dashboard
    useEffect(() => {

        //check token
        if (!localStorage.getItem('token')) {

            //redirect page dashboard
            history.push('/login');
        }

        // set header
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`
        //fetch user from Rest API
        axios.get(url + 'api/user')
            .then((response) => {
                //set response user to state
                if (response.data.status == "Token is Expired") {
                    localStorage.removeItem("token");
                    history.push('/login');
                }
                setAuth(response.data.user);
                setIsPending(false);
            }).catch(err => {
                console.log(err)
            })
    }, [setIsPending]);

    //function "loginHanlder"
    const changeHandler = async (e) => {
        e.preventDefault();
        if (newPassword != "") {
            if (newPassword == newPassword2) {
                //initialize formData
                const formData = new FormData();

                //append data to formData
                formData.append('email', auth.email);
                formData.append('password', password);
                formData.append('new-password', newPassword);

                //send data to server
                await axios.post(url + 'api/check-user', formData)
                    .then((response) => {
                        console.log(response)
                        alert(response.data.meta.message)
                        if(response.data.data == true){
                            history.push('/');
                        }else{
                            window.location.reload();
                        }
                    })
                    .catch((error) => {
                        //assign error to state "validation"
                        setValidation(error.response.data);
                    })
            } else {
                alert("Password dan Confirm Password Tidak Sama!");
            }
        } else {
            alert("Isi Password Baru Anda!");
        }
    };
    console.log(auth)
    return (
        <React.Fragment>
            {isPending &&
                <HalfMalf text={"Loading..."} bgColor={"#f8f9fa"} center={true} width={"150px"} height={"150px"} />
            }
            {!isPending &&
                <>
                    <Navbar />
                    <section className="sign-up-area ptb-100">
                        <div className="container">
                            <div className="scetion-title text-center">
                                <span>Change Password</span>
                                <h2>Ubah Password Anda</h2>
                                {/* <p>
                            It is a long established fact that a reader will be distracted by
                            the readable content of a page when looking at its layout.
                        </p> */}
                            </div>
                            <div className="contact-wrap-form log-in-width">
                                {
                                    validation.error && (
                                        <div className="alert alert-danger">
                                            {validation.error}
                                        </div>
                                    )
                                }
                                <form onSubmit={changeHandler}>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <input className="form-control" type="password" onChange={(event) => setPassword(event.target.value)} value={password} placeholder="Password Lama" />
                                        </div>
                                    </div>
                                    {
                                        validation.password && (
                                            <div className="alert alert-danger">
                                                {validation.password[0]}
                                            </div>
                                        )
                                    }

                                    <div className="col-12">
                                        <div className="form-group">
                                            <input className="form-control" type="password" onChange={(event) => setNewPassword(event.target.value)} value={newPassword} placeholder="Password Baru" />
                                        </div>
                                    </div>
                                    {
                                        validation.password && (
                                            <div className="alert alert-danger">
                                                {validation.newPassword[0]}
                                            </div>
                                        )
                                    }

                                    <div className="col-12">
                                        <div className="form-group">
                                            <input className="form-control" type="password" onChange={(event) => setNewPassword2(event.target.value)} value={newPassword2} placeholder="Confirm Password Baru" />
                                            {newPassword != newPassword2 ?
                                                <p className="text-danger">
                                                    Password Tidak Sama
                                                </p>
                                                :
                                                ""
                                            }
                                        </div>
                                    </div>
                                    {
                                        validation.password && (
                                            <div className="alert alert-danger">
                                                {validation.newPassword2[0]}
                                            </div>
                                        )
                                    }

                                    <div class="col-12">
                                        <button class="default-btn btn-two" type="submit">
                                            Change Password
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </>
            }
        </React.Fragment>
    );
}

export default ChangePassword;