import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'

const Register = () => {
    const url = "https://lbw-edunesia.com/api-lbw/public/";
    //define state validation
    const [validation, setValidation] = useState([]);

    const history = useHistory();
    const [name, setNama] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password_confirmation, setConfirmPassword] = useState("");
    const role = "Member";
    // const member = {name, email, password, role, password_confirmation}

    const registerHandler = async (e) => {
        e.preventDefault();

        //initialize formData
        const formData = new FormData();

        //append data to formData
        formData.append('name', name);
        formData.append('email', email);
        formData.append('password', password);
        formData.append('password_confirmation', password_confirmation);
        formData.append('role', role);

        //send data to server
        await axios.post(url+'api/register', formData)
            .then(() => {

                //redirect to logi page
                history.push('/login');
            })
            .catch((error) => {

                //assign error to state "validation"
                setValidation(error.response.data);
            })
    };

    useEffect(() => {

        //check token
        if (localStorage.getItem('token')) {
            history.push('/');
        }
    }, []);

    console.log(validation)
    return (
        <React.Fragment>
            <Navbar />
            <section className="sign-up-area ptb-100">
                <div className="container">
                    <div className="scetion-title text-center">
                        <span>Daftar</span>
                        <h2>Buat sebuah akun!</h2>
                        {/* <p>
                            It is a long established fact that a reader will be distracted by
                            the readable content of a page when looking at its layout.
                        </p> */}
                    </div>
                    <div className="contact-wrap-form log-in-width">
                        <form onSubmit={registerHandler}>
                            <div className="col-12">
                                <div className="form-group">
                                    <input className="form-control" type="text" onChange={(event) => setNama(event.target.value)} value={name} placeholder="Nama" />
                                </div>
                            </div>
                            {
                                validation.name && (
                                    <div className="alert alert-danger">
                                        {validation.name[0]}
                                    </div>
                                )
                            }
                            <div className="col-12">
                                <div className="form-group">
                                    <input className="form-control" type="email" onChange={(event) => setEmail(event.target.value)} value={email} placeholder="Email" />
                                </div>
                            </div>
                            {
                                validation.email && (
                                    <div className="alert alert-danger">
                                        {validation.email[0]}
                                    </div>
                                )
                            }
                            <div className="col-12">
                                <div className="form-group">
                                    <input className="form-control" type="password" onChange={(event) => setPassword(event.target.value)} value={password} placeholder="Password" />
                                </div>
                            </div>
                            {
                                validation.password && (
                                    <div className="alert alert-danger">
                                        {validation.password[0]}
                                    </div>
                                )
                            }
                            <div className="col-12">
                                <div className="form-group">
                                    <input className="form-control" type="password" onChange={(event) => setConfirmPassword(event.target.value)} value={password_confirmation} placeholder="Confirm Password" />
                                </div>
                            </div>
                            <div className="col-12 text-center">
                                <button className="default-btn btn-two" type="submit">
                                    Daftar
                                </button>
                            </div>
                            <div className="col-12">
                                <p className="account-desc">
                                    Sudah punya akun?
                                    <Link to="/login">Login</Link>
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    );
}

export default Register;