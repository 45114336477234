import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from 'axios';
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'
import { HalfMalf }
    from 'react-spinner-animated';

const BlogDetail = () => {
    const url = "https://lbw-edunesia.com/api-lbw/public/";
    // const url = "http://localhost:8000/";
    const [blog, setBlog] = useState([]);
    const [isPending, setIsPending] = useState(true);
    const { id } = useParams();
    // const history = useHistory();
    useEffect(()=>{
        axios.get(url + 'api/blog?id=' + id)
        .then((response) => {
            //set response user to state
            setBlog(response.data.data)
            setIsPending(false)
        }).catch(err => console.log(err))
    },[setIsPending])
    
    return (
        <React.Fragment>
            {isPending && <HalfMalf text={"Loading..."} bgColor={"#f8f9fa"} center={true} width={"150px"} height={"150px"} />}
            {!isPending &&
                <>
                    <Navbar />
                    <div className="blog-dtls ptb-100">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="blog-dtls-content">
                                        <div className="blog-dtls-img">
                                            <img src={blog.image} alt="Blog Images" />
                                        </div>
                                        <div className="blog-text">
                                            <h2>{blog.judul}</h2>
                                            <div dangerouslySetInnerHTML={{ __html: blog.content }}></div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-lg-4">
                                    <div className="widget-area">
                                        <section className="widget widget-popular-post">
                                            <h3 className="widget-title">Popular Posts</h3>
                                            <div className="post-wrap">
                                                <article className="item">
                                                    <a href="blog-details.html" className="thumb">
                                                        <span className="fullimage cover bg1" role="img" />
                                                    </a>
                                                    <div className="info">
                                                        <time dateTime="2020-06-30">Jun 20, 2020</time>
                                                        <h4 className="title usmall">
                                                            <a href="blog-details.html">
                                                                Basic Guidline Layout for SEO  Bigenner Level
                                                            </a>
                                                        </h4>
                                                    </div>
                                                </article>
                                                <article className="item">
                                                    <a href="blog-details.html" className="thumb">
                                                        <span className="fullimage cover bg2" role="img" />
                                                    </a>
                                                    <div className="info">
                                                        <time dateTime="2020-06-30">Jun 21, 2020</time>
                                                        <h4 className="title usmall">
                                                            <a href="blog-details.html">
                                                                How to Become Most Skilled Person in Social Marketing
                                                            </a>
                                                        </h4>
                                                    </div>
                                                </article>
                                                <article className="item">
                                                    <a href="blog-details.html" className="thumb">
                                                        <span className="fullimage cover bg3" role="img" />
                                                    </a>
                                                    <div className="info">
                                                        <time dateTime="2020-06-30">Jun  22, 2020</time>
                                                        <h4 className="title usmall">
                                                            <a href="blog-details.html">
                                                                Basic Guidline Layout for SEO Bigenner Level
                                                            </a>
                                                        </h4>
                                                    </div>
                                                </article>
                                                <article className="item">
                                                    <a href="blog-details.html" className="thumb">
                                                        <span className="fullimage cover bg4" role="img" />
                                                    </a>
                                                    <div className="info">
                                                        <time dateTime="2020-06-30">Jun 23, 2020</time>
                                                        <h4 className="title usmall">
                                                            <a href="blog-details.html">
                                                                How to Become Most Skilled Person in Social Marketing
                                                            </a>
                                                        </h4>
                                                    </div>
                                                </article>
                                            </div>
                                        </section>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <Footer />
                </>
            }
        </React.Fragment>
    );
}

export default BlogDetail;