import React, { useEffect, useContext } from "react";
import axios from 'axios';
import { AuthContext } from '../../AuthContext';
import { UserContext } from '../../UserContext';
import { useHistory } from 'react-router';

const Logout = () => {
    const url = "https://lbw-edunesia.com/api-lbw/public/";
    const {isAuthenticated, setIsAuthenticated} = useContext(AuthContext)
    const {user, setUser} = useContext(UserContext)
    const history = useHistory();

    useEffect(()=>{
        //set axios header dengan type Authorization + Bearer token
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`
        //fetch Rest API
        axios.post(url+'api/logout')
        .then(() => {

            //remove token from localStorage
            localStorage.removeItem("token");
            setIsAuthenticated(false)
            setUser([])
            //redirect halaman login
            history.push('/login');
        });
    },[setIsAuthenticated])
    
    return ( 
        <React.Fragment>

        </React.Fragment>
     );
}
 
export default Logout;