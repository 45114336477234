import React, { useEffect, useState } from 'react';
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'
import axios from 'axios';
import { useHistory } from "react-router";
import { HalfMalf } 
from 'react-spinner-animated';

const Transaction = () => {
    const url = "https://lbw-edunesia.com/api-lbw/public/";
    // const url = "http://localhost:8000/";
    const [transaction, setTransaction] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [auth, setAuth] = useState([]);
    const history = useHistory();

    useEffect(() => {
        // set header
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`
        //fetch user from Rest API
        axios.get(url + 'api/user')
            .then((response) => {
                //set response user to state
                if(response.data.status == "Token is Expired"){
                    localStorage.removeItem("token");
                    history.push('/login');
                }
                setAuth(response.data.user);
                //fetch transaction from Rest API
                axios.get(url + 'api/transaction?id_user=' + response.data.user.id)
                    .then((response) => {
                        //set response user to state
                        // setPesanan(response.data.data)
                        let pesanan = [];
                        response.data.data.map((items) => {
                            items.transaction_detail.map((item) => {
                                let data = {
                                    id: item.id,
                                    order_id: items.order_id,
                                    gambar: item.module_data.image,
                                    module: item.module_data.nama,
                                    paket: item.nama_paket,
                                    harga: item.harga_paket,
                                    status: items.status,
                                    tanggal: items.tanggal
                                }
                                pesanan.push(data);
                            })
                        })
                        setTransaction(pesanan)
                        setIsPending(false);
                    }).catch(err => {
                        console.log(err)
                    })
            }).catch(err => console.log(err))
    }, [setIsPending])

    return (
        <React.Fragment>
            {isPending &&
                <HalfMalf text={"Loading..."} bgColor={"#f8f9fa"} center={true} width={"150px"} height={"150px"}/>
            }
            {!isPending &&
                <>
                <Navbar />
                <section className="contact-section pt-20 pb-20">
                    <div className="container">
                        <div className="section-title">
                            <h2>My Transaction</h2>
                            <p>
                                Daftar Pembelian Kelas Anda
                            </p>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="card mb-4">
                                    <div className="card-body px-0 pt-0 pb-2">
                                        <div className="table-responsive p-0">
                                            <table className="table align-items-center mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Order Id</th>
                                                        <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Cover</th>
                                                        <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Module</th>
                                                        <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Paket</th>
                                                        <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Harga</th>
                                                        <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Tanggal</th>
                                                        <th className="text-secondary opacity-7" />
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {transaction.map((detail) => {
                                                        return (
                                                            <tr key={detail.id}>
                                                                <td>
                                                                    {detail.order_id}
                                                                </td>
                                                                <td className="align-middle text-center">
                                                                    <img src={detail.gambar} alt="" height={80}/>
                                                                </td>
                                                                <td className="align-middle text-center text-sm">
                                                                    {detail.module}
                                                                </td>
                                                                <td className="align-middle text-center">
                                                                    {detail.paket}
                                                                </td>
                                                                <td className="align-middle text-center">
                                                                    Rp. {parseInt(detail.harga).toLocaleString({})}
                                                                </td>
                                                                <td className="align-middle text-center">
                                                                {(detail.status) == "settlement"  ?
                                                                    <span className="badge badge-sm bg-gradient-success">{detail.status}</span>
                                                                : ((detail.status) == "pending" ?
                                                                    <span className="badge badge-sm bg-gradient-secondary">{detail.status}</span>
                                                                :
                                                                    <span className="badge badge-sm bg-gradient-danger">{detail.status}</span>
                                                                )}
                                                                
                                                                    
                                                                </td>
                                                                <td>
                                                                    {detail.tanggal}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                </>
            }
            
        </React.Fragment>
    );
}

export default Transaction;