import React, { useState, useEffect } from "react";
import { useTimer } from "react-timer-hook";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import emailjs from '@emailjs/browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { HalfMalf }
  from 'react-spinner-animated';

const Quiz = () => {
  const url = "https://lbw-edunesia.com/api-lbw/public/";
  // const url = "http://localhost:8000/";
  const history = useHistory();
  const { id } = useParams();
  const { durasi } = useParams();
  const [auth, setAuth] = useState([]);
  const [mapel, setMapel] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [quiz, setQuiz] = useState([]);
  const [profile, setProfile] = useState(null);
  const [score, setScore] = useState({
    correct: 0,
    false: 0,
  });
  const [isPending, setIsPending] = useState(true);
  const [finish, setFinish] = useState(false);
  // const { idSoal, question, options } = quiz[currentIndex];
  console.log(durasi)
  const MINUTES = durasi * 60;
  const time = new Date();
  time.setSeconds(time.getSeconds() + MINUTES);

  const { seconds, minutes, hours } = useTimer({
    expiryTimestamp: time,
    onExpire: () => {
      setCurrentIndex(quiz.length - 1)
      setFinish(true)
    }
  });

  const checkScore = () => {
    const questionAnswered = quiz.filter((item) => {
      return item.selected
    });
    const questionCorrect = questionAnswered.filter((item) =>
      item.options.find(
        (option) => option.correct && option.selected === option.correct
      )
    );
    setScore({
      correct: questionCorrect.length,
      false: quiz.length - questionCorrect.length,
    });
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      history.push('/');
    }
    // set header
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`
    //fetch user from Rest API
    axios.get(url + 'api/user')
      .then((response) => {
        //set response user to state
        if (response.data.status == "Token is Expired") {
          localStorage.removeItem("token");
          history.push('/login');
        }
        setAuth(response.data.user);
        axios.get(url + 'api/profile?id_user=' + response.data.user.id)
          .then((response) => {
            //set response user to state
            setProfile(response.data.data)
            // setIsFetch(true)
          }).catch(err => {
            console.log(err)
            setProfile({
              id_user: "",
              hp_user: "",
              pendidikan: "",
              nama_ortu: "",
              email_ortu: "",
              hp_ortu: "",
              alamat: "",
            })
          })
      }).catch(err => console.log(err))

    axios.get(url + 'api/soal?id_mapel=' + id)
      .then((response) => {
        //set response user to state
        let soals = []
        response.data.data.forEach((element, index) => {
          // console.log(element.quiz_data.jumlah_soal);
          if (index < element.quiz_data.jumlah_soal) {
            let soal = {
              id: element.id,
              question: element.soal,
              gambar: element.gambar,
              options: [
                {
                  simbol: "A",
                  title: element.a,
                  correct: element.jawaban === "A" ? true : false,
                },
                {
                  simbol: "B",
                  title: element.b,
                  correct: element.jawaban === "B" ? true : false,
                },
                {
                  simbol: "C",
                  title: element.c,
                  correct: element.jawaban === "C" ? true : false,
                },
                {
                  simbol: "D",
                  title: element.d,
                  correct: element.jawaban === "D" ? true : false,
                },
                {
                  simbol: "E",
                  title: element.e,
                  correct: element.jawaban === "E" ? true : false,
                },
              ],
            }
            soals.push(soal);
          }
        });
        setQuiz(soals)
        setIsPending(false)
      }).catch(err => console.log(err))

    axios.get(url + 'api/mapel?id=' + id)
      .then((response) => {
        //set response user to state
        setMapel(response.data.data)
        // setIsFetch(true)
      }).catch(err => console.log(err))
  }, [setIsPending]);

  useEffect(() => {
    checkScore();
  }, [quiz])

  const nextQuestion = () => {
    if (quiz.length - 1 === currentIndex) return;
    setCurrentIndex(currentIndex + 1);
  };

  const previousQuestion = () => {
    if (currentIndex === 0) return;
    setCurrentIndex(currentIndex - 1);
  };

  const selectOption = (indexSelected, indexOptionSelected) => {
    // checkScore();

    setQuiz(
      quiz.map((item, index) =>
        index === indexSelected
          ? {
            ...item,
            selected: true,
            options: item.options.map((item, index) =>
              index === indexOptionSelected
                ? { ...item, selected: true }
                : { ...item, selected: false }
            ),
          }
          : item
      )
    );
  };

  const sendMail = (e) => {
    e.preventDefault();
    // set penerima email
    var orangTua = {
      from_name: 'Little Bookworm',
      message: 'Selamat ' + auth.name + ' kamu mendapatkan nilai ' + ((score.correct / quiz.length) * 100).toFixed(2),
      name: auth.name,
      to_email: profile.email_ortu,
    };
    var mentor = {
      from_name: 'Little Bookworm',
      message: 'Member ' + auth.name + ' mendapatkan nilai ' + ((score.correct / quiz.length) * 100).toFixed(2) + ' pada ujian ' + mapel.nama,
      name: mapel.mentor_data.name,
      to_email: mapel.mentor_data.email,
    };
    var customer = {
      from_name: 'Little Bookworm',
      message: 'Member ' + auth.name + ' mendapatkan nilai ' + ((score.correct / quiz.length) * 100).toFixed(2) + ' pada ujian ' + mapel.nama,
      name: mapel.mentor_data.name,
      to_email: auth.email,
    };
    // send email
    emailjs.send('service_e74r7th', 'template_s0f3zla', orangTua, 'user_kkJC8lEktbandUvp4d99b')
      .then((result) => { console.log("email dikirim") },
        (error) => { console.log(error.text) });
    emailjs.send('service_e74r7th', 'template_s0f3zla', mentor, 'user_kkJC8lEktbandUvp4d99b')
      .then((result) => { console.log("email dikirim") },
        (error) => { console.log(error.text) });
    emailjs.send('service_e74r7th', 'template_s0f3zla', customer, 'user_kkJC8lEktbandUvp4d99b')
      .then((result) => { console.log("email dikirim") },
        (error) => { console.log(error.text) });
    // get date
    const current2 = new Date();
    const date = `${current2.getFullYear()}-${current2.getMonth() + 1}-${current2.getDate()}`;

    let answer = [];
    quiz.map((item) => {
      item.options.map((poin) => {
        if (poin.selected) {
          answer.push([item.id, poin.simbol])
        }
      })
    })

    let formData = {
      id_mapel: mapel.id,
      id_user: auth.id,
      tanggal: date,
      nilai: ((score.correct / quiz.length) * 100).toFixed(2),
      answer: answer,
    }

    // post data
    axios.post(url + 'api/quiz', formData)
      .then((response) => {
        console.log("sukses")
        history.push({
          pathname: "/summary",
          state: {
            quiz,
            score,
          },
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }
  // console.log(durasi)
  return (
    <div>
      {isPending &&
        <HalfMalf text={"Loading..."} bgColor={"#f8f9fa"} center={true} width={"150px"} height={"150px"} />
      }
      {!isPending &&
        <div className="container mt-5 pt-4">
          <div className="row">
            <div className="col-md-12">
              {/* nav */}
              <div className="card mb-3">
                <div className="card-header">
                  <h4 className="text-center">
                    Sisa Waktu: {hours}:
                    {minutes}:{seconds}
                  </h4>
                </div>
                <div
                  className="card-body"
                  style={{
                    display: "flex",
                    padding: 10,
                    flexWrap: "wrap",
                    minHeight: "50%"
                  }}
                >
                  {!finish &&
                    quiz.map((item, index) => (
                      <div
                        key={index}
                        className="border border-primary font-weight-bold"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: 40,
                          width: 40,
                          marginRight: 5,
                          marginBottom: 5,
                          borderRadius: 5,
                          cursor: "pointer",
                          color: 'black',
                          backgroundColor:
                            index === currentIndex
                              ? "#0468e0"
                              : item?.selected
                                ? "#04428d"
                                : "transparent",
                        }}
                        onClick={() => setCurrentIndex(index)}
                      >
                        {index + 1}
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {/* soal */}
              <div className="card">
                <div
                  className="card-header bg-white font-weight-bold"
                  style={{
                    fontSize: 20,
                  }}
                >
                  {currentIndex + 1}. {quiz[currentIndex].question}
                  <br/>
                  <img src={quiz[currentIndex].gambar} alt="" />
                </div>
                <div className="card-body">
                  {quiz[currentIndex].options.map((item, index) => (
                    <div
                      style={{
                        display: "flex",
                        justifyItems: "center",
                        alignItems: "center",
                        fontSize: 18,
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          height: 20,
                          width: 20,
                          borderRadius: 100,
                          backgroundColor: item?.selected ? "greenyellow" : "grey",
                          cursor: "pointer",
                          marginRight: 5,
                        }}
                        onClick={() => selectOption(currentIndex, index)}
                      />
                      {item.simbol}. {item.title}
                    </div>
                  ))}
                </div>
              </div>
              {/* footnav */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: 10,
                }}
              >
                <button
                  style={{
                    backgroundColor: '#04428d',
                    color: 'white',
                  }}
                  className="btn col-sm-2"
                  onClick={() => previousQuestion()}
                  disabled={!finish ? currentIndex === 0 ? true : false : true}
                >
                  <FontAwesomeIcon icon={faAngleLeft} /> Prev
                </button>
                {quiz.length - 1 === currentIndex ? (
                  <Link
                    className="btn btn-success col-sm-2"
                    onClick={sendMail}
                    to={{
                      pathname: "/summary",
                      state: {
                        quiz,
                        score,
                      },
                    }}
                  >
                    Finish
                  </Link>
                ) : (
                  <button
                    style={{
                      backgroundColor: '#04428d',
                      color: 'white'
                    }}
                    className="btn col-sm-2"
                    onClick={() => nextQuestion()}
                  >
                    Next <FontAwesomeIcon icon={faAngleRight} />
                  </button>
                )}
              </div>
            </div>

          </div>
        </div>
      }
    </div>
  );
};

export default Quiz;
