import React, { useEffect, useState, useContext } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import { AuthContext } from '../../AuthContext';
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'
import Pagination from "react-js-pagination";
import { HalfMalf } from 'react-spinner-animated';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from "@fortawesome/free-solid-svg-icons";

const Blog = () => {
    const url = "https://lbw-edunesia.com/api-lbw/public/";
    // const url = "http://localhost:8000/";
    const [blog, setBlog] = useState(null)
    const [page, setPage] = useState(null)
    const [isPending, setIsPending] = useState(true)
    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext)
    const [frontend, setFrontend] = useState([]);

    const fetchData = async (pageNumber = 1) => {
        await axios.get(url + 'api/blog?page=' + pageNumber)
            .then((response) => {
                //set response user to state
                setBlog(response.data.data.data)
                setPage(response.data.data)
                setIsPending(false)
                // setIsFetch(true)
            }).catch(err => console.log(err))
    };

    useEffect(() => {
        if (localStorage.getItem("token")) {
            setIsAuthenticated(true)
        }
        axios.get(url + 'api/frontend')
            .then((response) => {
                //set response user to state
                setFrontend(response.data.data)
                // setIsFetch(true)
            }).catch(err => console.log(err))

        fetchData();
    }, [setIsPending])

    return (
        <React.Fragment>
            {isPending &&
                <HalfMalf text={"Loading..."} bgColor={"#f8f9fa"} center={true} width={"150px"} height={"150px"} />
            }
            {!isPending &&
                <>
                    <Navbar />
                    <div className="inner-banner inner-bg7">
                        <div className="container">
                            <div className="inner-title text-center">
                                <h3>Blog</h3>
                                <ul>
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li>
                                        <i className="bx bxs-chevrons-right" />
                                    </li>
                                    <li>Blog</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <section className="portfolio-area pt-100 pb-70">
                        <div className="container">
                            <div className="scetion-title text-center">
                                <span>Blog</span>
                                <h2>{frontend.blog_text}</h2>
                            </div>
                            <div className="row pt-45">
                                {blog.map((news) => {
                                    return (
                                        <div className="col-lg-4 col-md-6" key={news.id}>
                                            <div className="blog-card" style={{ marginRight: '15px' }}>
                                                <div className="blog-img">
                                                    <Link to={`blog/${news.id}`}>
                                                        <img src={news.image} alt="Blog Images" height={340} width={340} />
                                                    </Link>
                                                </div>
                                                <div className="blog-content">
                                                    <Link to={`blog/${news.id}`}>
                                                        <h3>{news.judul}</h3>
                                                    </Link>
                                                    <Link to={`blog/${news.id}`} className="read-btn">Read More</Link>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                <div style={{ marginLeft: '40%' }}>
                                    <Pagination
                                        activePage={page?.current_page ? page?.current_page : 0}
                                        itemsCountPerPage={page?.per_page ? page?.per_page : 0}
                                        totalItemsCount={page?.total ? page?.total : 0}
                                        pageRangeDisplayed={page?.per_page ? page?.per_page : 0}
                                        onChange={(pageNumber) => {
                                            fetchData(pageNumber)
                                        }}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        firstPageText="<<"
                                        lastPageText=">>"
                                        nextPageText=">"
                                        prevPageText="<"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </>
            }
        </React.Fragment>
    );
}

export default Blog;