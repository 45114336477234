import React, { useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import axios from 'axios';
import '../../App.css';
import { useHistory } from 'react-router';
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'
import OwlCarousel from 'react-owl-carousel2';
import { AuthContext } from '../../AuthContext';
import { HalfMalf }
    from 'react-spinner-animated';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from "@fortawesome/free-solid-svg-icons";

const cartFromLocalStorage = JSON.parse(localStorage.getItem("cart") || "[]")

const ModuleDetail = () => {
    const url = "https://lbw-edunesia.com/api-lbw/public/";
    // const url = "http://localhost:8000/";
    const [module, setModule] = useState(null);
    const [rekomendasi, setRekomendasi] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const { id } = useParams();
    const history = useHistory();
    const [keranjang, setKeranjang] = useState(cartFromLocalStorage);
    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);

    const options = {
        items: 3,
    };

    const options2 = {
        items: 3,
        nav: false,
        dots: false
    };

    function beli(idModule, namaModule, image, idPaket, namaPaket, ketPaket, harga, durasi) {
        let cartBaru = {
            "idModule": idModule,
            "namaModule": namaModule,
            "image": image,
            "idPaket": idPaket,
            "namaPaket": namaPaket,
            "ket": ketPaket,
            "harga": harga,
            "durasi": durasi,
        }

        keranjang.push(cartBaru);

        let cartString = JSON.stringify(keranjang);
        localStorage.setItem('cart', cartString);
    }

    useEffect(() => {
        // fetch module from Rest API
        axios.get(url + 'api/module?id=' + id)
            .then((response) => {
                //set response user to state
                setModule(response.data.data)
                // setIsFetch(true)
            }).catch(err => console.log(err))

        axios.get(url + 'api/module?rekomid=' + id)
            .then((response) => {
                //set response user to state
                setRekomendasi(response.data.data)
                setIsPending(false)
                // setIsFetch(true)
            }).catch(err => console.log(err))
    }, [setIsPending])

    return (
        <React.Fragment>
            {isPending && <HalfMalf text={"Loading..."} bgColor={"#f8f9fa"} center={true} width={"150px"} height={"150px"} />}
            {!isPending &&
                <>
                    <Navbar />
                    <div className="inner-banner inner-bg7">
                        <div className="container">
                            <div className="inner-title text-center">
                                <h3>Detail Module</h3>
                                <ul>
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li>
                                        <i className="bx bxs-chevrons-right" />
                                    </li>
                                    <Link to="/module">Module</Link>
                                    <li>
                                        <i className="bx bxs-chevrons-right" />
                                    </li>
                                    <li>Detail Module</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div key={module.id}>
                        <div className="contact-wrap pt-45">
                            <div className="contact-wrap-form">
                                <div className="row">
                                    <div class="col-md-6">
                                        <div class="about-img about-ml">
                                            <img src={module.image} alt="Module Images" width="450" height="410" />
                                        </div>
                                    </div>
                                    <div class="col-md-6 text-left">
                                        <h3>{module.nama}</h3>
                                        <div className="portfolio-tag">
                                            <button className="btn btn-primary rounded-pill">{module.category_data.category}</button>
                                        </div>
                                        <h6><FontAwesomeIcon icon={faStar} style={{color:'#ffd700'}}/> {module.rating}</h6>
                                        <p className="text-left">Keterangan:</p>
                                        <div className="text-left" dangerouslySetInnerHTML={{ __html: module.ket }}></div>
                                        <p className="text-left">Mata Pelajaran:</p>
                                        <ul style={{ marginLeft: '-25px' }}>
                                            {module.mapel_module_data.map((mapel) => {
                                                return <li>{mapel.mapel_data.nama}</li>
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                <div className="price-area bg-price pt-100 pb-70">
                                    <div className="container">
                                        <div className="scetion-title text-center">
                                            <span>Harga Paket</span>
                                            <h2>We Have Pre-ready Pricing for Our Services</h2>
                                            <p>
                                                It is a long established fact that a reader will be
                                                distracted by the readable content of a page when looking at its layout.
                                            </p>
                                        </div>
                                        <div id="prices-content">
                                            <div id="monthly" className="active prices-content-area animated">
                                                <div className="row">
                                                    {module.paket_data.map((paket) => {
                                                        return (
                                                            <div className="col-lg-4 col-sm-6" key={paket.id}>
                                                                <div className="single-price" style={{ minHeight: '585px' }}>
                                                                    <span>{paket.nama}</span>
                                                                    <div className="single-price-title">

                                                                        <h2><sup>Rp. </sup>{parseInt(paket.harga).toLocaleString(undefined, {})}<sub>/{paket.durasi} bulan</sub></h2>
                                                                    </div>
                                                                    <ul>
                                                                        <div style={{ minHeight: '310px', paddingLeft: '50px', paddingTop: '20px' }}>
                                                                            <h5 className="text-left">Benefit:</h5>
                                                                            <div className="ul" dangerouslySetInnerHTML={{ __html: paket.ket }}></div>
                                                                        </div>
                                                                    </ul>
                                                                    <Link to={`pembayaran/${paket.id}`} onClick={() => beli(module.id, module.nama, module.image, paket.id, paket.nama, paket.ket, paket.harga, paket.durasi)} className="get-btn">Beli</Link>
                                                                    {/* // <button className="get-btn">Get Stated</button> */}
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                {module.testi_data.length > 0 ?
                                                    <>
                                                        <div className="row mt-5">
                                                            <div className="col-md-12">
                                                                <h4>Testimonial</h4>
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{ marginTop: '-60px' }}>
                                                            <OwlCarousel options={options2}>
                                                                {module.testi_data.map((testi) => {
                                                                    return (
                                                                        <div className="col-lg-4 col-md-6" key={testi.id}>
                                                                            <div className="client-item client-mt" style={{ width: '320%' }}>
                                                                                <div className="text-left pt-3">
                                                                                    {testi.tanggal}
                                                                                </div>
                                                                                <h3 style={{ marginTop: '-60px' }}>{testi.user_data.name}</h3>
                                                                                <p>
                                                                                    {testi.pesan}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </OwlCarousel>
                                                        </div>
                                                    </>
                                                    :
                                                    ""
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row pt-20">
                            <h4 style={{ marginBottom: '20px' }}>Rekomendasi Module</h4>
                            <OwlCarousel options={options}>
                                {rekomendasi === null ?
                                    <p>Loading...</p>
                                    :
                                    rekomendasi.map((mod) => {
                                        return (
                                            <div className="portfolio-item" key={mod.id}>
                                                <div className="portfolio-img">
                                                    <Link to={`/module/${mod.id}`}>
                                                        <img src={mod.image} alt="Module Images" width={510} height={350} />
                                                    </Link>
                                                    <div className="portfolio-tag">
                                                        <Link to={`/module/${mod.id}`}><span>{mod.category_data.category}</span></Link>
                                                    </div>
                                                    <div className="portfolio-content">
                                                        {isAuthenticated &&
                                                            <Link to={`/module/${mod.id}`}>
                                                                <h3>{mod.nama}</h3>
                                                                <i className="bx bxs-chevron-right" />
                                                            </Link>
                                                        }
                                                        {!isAuthenticated &&
                                                            <Link to="/login">
                                                                <h3>{mod.nama}</h3>
                                                                <i className="bx bxs-chevron-right" />
                                                            </Link>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </OwlCarousel>
                        </div>
                    </div>
                    <Footer />
                </>
            }
        </React.Fragment>
    );
}

export default ModuleDetail;