import logo from './logo.svg';
import './App.css';
import 'react-spinner-animated/dist/index.css'
import Footer from './components/Footer'
import Navbar from './components/Navbar'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Home from './page/home/Home'
import Contact from './page/home/Contact'
import ChangePassword from './page/home/ChangePassword'
import Login from './page/auth/Login'
import Register from './page/auth/Register'
import Logout from './page/auth/Logout'
import Profile from './page/user/Profile'
import Transaction from './page/user/Transaction'
import Module from './page/module/Module'
import ModuleCategory from './page/module/ModuleCategory'
import ModuleDetail from './page/module/ModuleDetail'
import Pembayaran from './page/module/Pembayaran'
import Checkout from './page/module/Checkout'
import Kelas from './page/kelas/Kelas'
import KelasDetail from './page/kelas/KelasDetail'
import Materi from './page/kelas/Materi'
import VideoPlayer from './page/kelas/VideoPlayer'
import Tugas from './page/kelas/Tugas'
import Rekap from "./page/kelas/Rekap";
//import hook react
import React, { useState, useEffect } from 'react';
//import hook useHitory from react router dom
import { useHistory } from 'react-router';
//import axios
import axios from 'axios';
// import context
import {AuthContext} from "./AuthContext"
import {UserContext} from "./UserContext"
import Quiz from "./page/quiz/Quiz3";
import Start from "./page/quiz/start";
import HistoryQuiz from "./page/quiz/HistoryQuiz";
import QuizSummary from "./page/QuizSummary";
import BlogDetail from "./page/blog/BlogDetail";
import Blog from "./page/blog/Blog";

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState([]);

    console.log(user)
    return (
      <div className="App">
        <AuthContext.Provider value={{isAuthenticated, setIsAuthenticated}}>
        <UserContext.Provider value={{user, setUser}}>
            <Router>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/contact" component={Contact} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/register" component={Register} />
                    <Route exact path="/logout" component={Logout} />
                    <Route exact path="/profile" component={Profile} />
                    <Route exact path="/change-password" component={ChangePassword} />
                    <Route exact path="/module" component={Module} />
                    <Route exact path="/blog" component={Blog} />
                    <Route exact path="/module/:id" component={ModuleDetail} />
                    <Route exact path="/module-category/:id" component={ModuleCategory} />
                    <Route exact path="/blog/:id" component={BlogDetail} />
                    <Route exact path="/module/pembayaran/:id" component={Pembayaran} />
                    <Route exact path="/checkout" component={Checkout} />
                    <Route exact path="/transaction" component={Transaction} />
                    <Route exact path="/kelas" component={Kelas} />
                    <Route exact path="/kelas/:id" component={KelasDetail} />
                    <Route exact path="/kelas/materi/:id" component={Materi} />
                    <Route exact path="/kelas/materi/play/:id" component={VideoPlayer} />
                    <Route exact path="/kelas/materi/tugas/:id" component={Tugas} />
                    <Route exact path="/kelas/materi/quiz/:id" component={Start} />
                    <Route exact path="/kelas/materi/quiz/start/:id/:durasi" component={Quiz} />
                    <Route exact path="/kelas/materi/quiz/summary/:id" component={HistoryQuiz} />
                    <Route exact path="/kelas/materi/rekap/:id" component={Rekap} />
                    <Route exact path="/summary" component={QuizSummary} />
                </Switch>
            </Router>
        </UserContext.Provider>
        </AuthContext.Provider>
      </div>
  );
}

export default App;
