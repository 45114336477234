import React, {useState, useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhoneAlt, faWhatsapp } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';

const Footer = () => {
    const url = "https://lbw-edunesia.com/api-lbw/public/";
    // const url = "http://localhost:8000/";
    const [frontend, setFrontend] = useState([]);
    const [isPending, setIsPending] = useState(true);
    const [wa, setWa] = useState("");

    useEffect(()=>{
        axios.get(url + 'api/frontend')
            .then((response) => {
                //set response user to state
                setFrontend(response.data.data)
                setWa('https://api.whatsapp.com/send/?phone='+response.data.data.kontak_wa+'&text&app_absent=0')
                setIsPending(false)
            }).catch(err => console.log(err))
    },[setIsPending]);

    return (
        <React.Fragment>
            <footer id="footer" className="footer-area-bg">
                <div className="footer-area-top pt-100 pb-70">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2 col-md-6">
                            {!isPending &&
                                <div className="footer-single">
                                    <div className="footer-single-content">
                                        <a href="index.html">
                                            <img src={frontend.footer_logo} alt="Logo" />
                                        </a>
                                        <p>{frontend.footer_text}</p>
                                    </div>
                                </div>
                            }
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="footer-list ml-50">
                                    <h4 style={{color:"#fff"}}>Kontak dan Sosial Media</h4>
                                    {!isPending &&
                                    <ul>
                                        <li>
                                            <div class="text-body2 sblack60--text" style={{fontSize:"16px"}}><FontAwesomeIcon icon={faEnvelope} /> {frontend.kontak_email}</div>
                                        </li>
                                        <li>
                                            <div class="text-body2 sblack60--text"><FontAwesomeIcon icon={faPhoneAlt} /> {frontend.kontak_hp}</div>
                                        </li>
                                        <li>
                                            <div class="text-body2 sblack60--text"><i class="fab fa-whatsapp" style={{fontSize:'24px'}}></i><a target="_blank" href={wa} className="ml-1">{frontend.kontak_wa}</a></div>
                                        </li>
                                    </ul>
                                    }
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="footer-list ml-50">
                                    <h4 style={{color:"#fff"}}>Layanan Pengiriman</h4>
                                    <ul>
                                        <li>
                                            <div class="text-body2 sblack60--text">
                                                <img src="assets/img/jne.png" alt="" height={35} style={{marginRight:"28px",marginBottom:'5px'}}/>
                                                <img src="assets/img/lion1.png" alt="" height={30} style={{marginRight:"5px",marginBottom:'5px'}}/>
                                                <img src="assets/img/logo-sicepat-2.png" alt="" height={30} style={{marginRight:"5px",marginBottom:'5px'}}/>
                                                <img src="assets/img/tiki.png" alt="" height={30} style={{marginRight:"5px",marginBottom:'5px'}}/>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="footer-list ml-50">
                                    <h4 style={{color:"#fff"}}>Metode Pembayaran</h4>
                                    <ul>
                                        <li>
                                            <div class="text-body2 sblack60--text">
                                                <img src="assets/img/mandiri2.png" alt="" height={30} style={{marginRight:"18px",marginBottom:'5px'}}/>
                                                <img src="assets/img/bri-1.png" alt="" height={30} style={{marginRight:"15px",marginBottom:'5px'}}/>
                                                <img src="assets/img/bni.png" alt="" height={30} style={{marginRight:"15px",marginBottom:'5px'}}/>
                                                <img src="assets/img/bca1.png" alt="" height={30} style={{marginRight:"5px",marginBottom:'5px'}}/>
                                                <img src="assets/img/bsi-1.png" alt="" height={30} style={{marginRight:"5px",marginBottom:'5px'}}/>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="bottom-text text-center">
                                    <p> Copyright © 2021 Little Bookworm Design By <a href="https://kactusidea.com/">Kactus Idea</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div>
            </div>
        </React.Fragment>
    );
}

export default Footer;