import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'
import emailjs from '@emailjs/browser';
import axios from 'axios';
import { HalfMalf }
    from 'react-spinner-animated';

const Contact = () => {
    const url = "https://lbw-edunesia.com/api-lbw/public/";
    // const url = "http://localhost:8000/";
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [subjek, setSubjek] = useState("")
    const [pesan, setPesan] = useState("")
    const [frontend, setFrontend] = useState([]);
    const [isPending, setIsPending] = useState(true);
    const [wa, setWa] = useState("");

    function kirimPesan(e) {
        e.preventDefault();
        var templateParams = {
            from_name: name,
            pesan: pesan,
            subjek: subjek,
            email: email,
        };
        emailjs.send('service_e74r7th', 'template_ksrl35p', templateParams, 'user_kkJC8lEktbandUvp4d99b')
            .then((result) => { alert("Pesan anda sudah dikirim!") },
                (error) => { console.log(error.text) });
    }

    useEffect(() => {
        axios.get(url + 'api/frontend')
            .then((response) => {
                //set response user to state
                setFrontend(response.data.data)
                setWa('https://api.whatsapp.com/send/?phone='+response.data.data.kontak_wa+'&text&app_absent=0')
                setIsPending(false)
            }).catch(err => console.log(err))
    }, [setIsPending]);

    return (
        <React.Fragment>
            {isPending &&
                <HalfMalf text={"Loading..."} bgColor={"#f8f9fa"} center={true} width={"150px"} height={"150px"} />
            }
            {!isPending &&
                <>
                    <Navbar />
                    <div className="contact-area pt-5 pb-70">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4">
                                    <h2>Kontak</h2>
                                    <div class="text-body2 sblack60--text" style={{ fontSize: "16px" }}><FontAwesomeIcon icon={faEnvelope} /> {frontend.kontak_email}</div>
                                    <div class="text-body2 sblack60--text"><FontAwesomeIcon icon={faPhoneAlt} /> {frontend.kontak_hp}</div>
                                    <div class="text-body2 sblack60--text"><i class="fab fa-whatsapp" style={{ fontSize: '24px' }}></i><a style={{ color: "grey" }} target="_blank" href={wa} className="ml-1">{frontend.kontak_wa}</a></div>
                                </div>
                                <div className="col-md-8">
                                    <div className="scetion-title text-center">
                                        <h2>Kirim Pesan</h2>
                                    </div>
                                    <div className="contact-wrap pt-45">
                                        <div className="contact-wrap-form">
                                            <form id="contactForm" onSubmit={kirimPesan}>
                                                <div className="row">
                                                    <div className="col-lg-6 col-sm-6">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                name="name"
                                                                id="name"
                                                                className="form-control"
                                                                required=""
                                                                data-error="Please enter your name"
                                                                placeholder="Nama Lengkap"
                                                                onChange={e => setName(e.target.value)}
                                                                value={name}
                                                            />
                                                            <div className="help-block with-errors" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-sm-6">
                                                        <div className="form-group">
                                                            <input
                                                                type="email"
                                                                name="email"
                                                                id="email"
                                                                className="form-control"
                                                                required=""
                                                                data-error="Please enter your email"
                                                                placeholder="Email"
                                                                onChange={e => setEmail(e.target.value)}
                                                                value={email}
                                                            />
                                                            <div className="help-block with-errors" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-sm-12">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                name="msg_subject"
                                                                id="msg_subject"
                                                                className="form-control"
                                                                required=""
                                                                data-error="Please enter your subject"
                                                                placeholder="Subjek Pesan"
                                                                onChange={e => setSubjek(e.target.value)}
                                                                value={subjek}
                                                            />
                                                            <div className="help-block with-errors" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="form-group">
                                                            <textarea
                                                                name="message"
                                                                className="form-control"
                                                                id="message"
                                                                cols={30}
                                                                rows={8}
                                                                required=""
                                                                data-error="Write your message"
                                                                placeholder="Pesan"
                                                                onChange={e => setPesan(e.target.value)}
                                                                value={pesan}
                                                            />
                                                            <div className="help-block with-errors" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12 text-center">
                                                        <button
                                                            type="submit"
                                                            className="default-btn page-btn text-center"
                                                        >
                                                            Kirim Pesan
                                                        </button>
                                                        <div id="msgSubmit" className="h3 text-center hidden" />
                                                        <div className="clearfix" />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Contact Area End */}

                    {/* Map Area Section Start */}
                    <div className="map-area">
                        <div className="container-fluid p-0">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1443.6480083334197!2d106.76709974534008!3d-6.129253996258996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6a1d79ada8397f%3A0x726bc2b806cef77f!2sLittle%20Bookworm!5e0!3m2!1sid!2sid!4v1645161247987!5m2!1sid!2sid" width="600" height="450" style={{ border: "0" }} allowfullscreen="" loading="lazy"></iframe>
                        </div>
                    </div>
                    {/* Map Area Section End*/}
                    <Footer />
                </>
            }
        </React.Fragment>
    );
}

export default Contact;