import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from 'axios';
import ReactPlayer from 'react-player/lazy';
import { useHistory } from 'react-router';
import Navbar2 from "../../components/Navbar2"
import Logo from '../../lbw-logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faLock } from '@fortawesome/free-solid-svg-icons'
import { HalfMalf }
    from 'react-spinner-animated';

const VideoPlayer = () => {
    const url = "https://lbw-edunesia.com/api-lbw/public/";
    // const url = "http://localhost:8000/";
    const [mapel, setMapel] = useState([]);
    const [materi, setMateri] = useState([]);
    const [isPending, setIsPending] = useState(true);
    const { id } = useParams();
    const history = useHistory();
    const [currentIdx, setCurrentIdx] = useState(0);
    const [lastMateri, setLastMateri] = useState(false);
    const [auth, setAuth] = useState([]);
    const [isLock, setIsLock] = useState(null);

    async function finish() {
        let doneData = {
            id_user: auth.id,
            id_materi: id,
        }
        // post data
        await axios.post(url + 'api/materi-done', doneData)
            .then((response) => {
                console.log("sukses")
            })
            .catch((error) => {
                console.log(error)
            })
    }

    async function nextPage() {
        let doneData = {
            id_user: auth.id,
            id_mapel: mapel.id,
            id_materi: id,
        }
        // post data
        await axios.post(url + 'api/materi-done', doneData)
            .then((response) => {
                console.log("sukses")
            })
            .catch((error) => {
                console.log(error)
            })

        history.push(`/kelas/materi/play/${currentIdx}`)
        window.location.reload();
    }

    const changePage = (destId) => {
        history.push(`/kelas/materi/play/${destId}`)
        window.location.reload();
    }

    useEffect(() => {
        if (!localStorage.getItem("token")) {
            history.push('/');
        }
        // set header
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`
        //fetch user from Rest API
        axios.get(url + 'api/user')
            .then((response) => {
                //set response user to state
                setAuth(response.data.user);
                if (response.data.status == "Token is Expired") {
                    localStorage.removeItem("token");
                    history.push('/login');
                }
            }).catch(err => console.log(err))

        axios.get(url + 'api/materi?id=' + id)
            .then((response) => {
                //set response user to state
                setMateri(response.data.data)
                axios.get(url + 'api/mapel?id=' + response.data.data.id_mapel)
                    .then((response) => {
                        //set response user to state
                        setMapel(response.data.data)
                        response.data.data.materi_data.forEach((materi, index) => {
                            if (materi.id == id) {
                                if (index + 1 < response.data.data.materi_data.length) {
                                    setCurrentIdx(response.data.data.materi_data[index + 1].id);
                                } else {
                                    setLastMateri(true);
                                }
                            }
                        });
                        axios.get(url + 'api/check-quiz?id_user=' + auth.id + '&id_mapel=' + response.data.data.id)
                            .then((response) => {
                                //set response user to state
                                console.log(response.data)
                                setIsLock(response.data.data)
                                setIsPending(false)

                            }).catch(err => console.log(err))
                    }).catch(err => console.log(err))
            }).catch(err => console.log(err))
    }, [setIsPending])
    // console.log(isLock)
    return (
        <React.Fragment>
            {isPending &&
                <HalfMalf text={"Loading..."} bgColor={"#f8f9fa"} center={true} width={"150px"} height={"150px"} />
            }
            {!isPending &&
                <>
                    <React.Fragment>
                        <aside
                            className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 "
                            id="sidenav-main"
                        >
                            <div className="sidenav-header">
                                <i
                                    className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
                                    aria-hidden="true"
                                    id="iconSidenav"
                                />
                                <Link
                                    className="navbar-brand m-0"
                                    to="/"
                                    target="_blank"
                                >
                                    <img
                                        src={Logo}
                                        className="navbar-brand-img h-100"
                                        alt="main_logo"
                                    />
                                    <span className="ms-1 font-weight-bold">Little Bookworm</span>
                                </Link>
                            </div>
                            <hr className="horizontal dark mt-0" />
                            <div
                                className="collapse navbar-collapse w-auto max-height-vh-100 h-100"
                                id="sidenav-collapse-main"
                            >
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <a className="nav-link  active">
                                            <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                                <svg
                                                    width="12px"
                                                    height="12px"
                                                    viewBox="0 0 45 40"
                                                    version="1.1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                >
                                                    <title>shop </title>
                                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                        <g
                                                            transform="translate(-1716.000000, -439.000000)"
                                                            fill="#FFFFFF"
                                                            fillRule="nonzero"
                                                        >
                                                            <g transform="translate(1716.000000, 291.000000)">
                                                                <g transform="translate(0.000000, 148.000000)">
                                                                    <path
                                                                        className="color-background opacity-6"
                                                                        d="M46.7199583,10.7414583 L40.8449583,0.949791667 C40.4909749,0.360605034 39.8540131,0 39.1666667,0 L7.83333333,0 C7.1459869,0 6.50902508,0.360605034 6.15504167,0.949791667 L0.280041667,10.7414583 C0.0969176761,11.0460037 -1.23209662e-05,11.3946378 -1.23209662e-05,11.75 C-0.00758042603,16.0663731 3.48367543,19.5725301 7.80004167,19.5833333 L7.81570833,19.5833333 C9.75003686,19.5882688 11.6168794,18.8726691 13.0522917,17.5760417 C16.0171492,20.2556967 20.5292675,20.2556967 23.494125,17.5760417 C26.4604562,20.2616016 30.9794188,20.2616016 33.94575,17.5760417 C36.2421905,19.6477597 39.5441143,20.1708521 42.3684437,18.9103691 C45.1927731,17.649886 47.0084685,14.8428276 47.0000295,11.75 C47.0000295,11.3946378 46.9030823,11.0460037 46.7199583,10.7414583 Z"
                                                                    />
                                                                    <path
                                                                        className="color-background"
                                                                        d="M39.198,22.4912623 C37.3776246,22.4928106 35.5817531,22.0149171 33.951625,21.0951667 L33.92225,21.1107282 C31.1430221,22.6838032 27.9255001,22.9318916 24.9844167,21.7998837 C24.4750389,21.605469 23.9777983,21.3722567 23.4960833,21.1018359 L23.4745417,21.1129513 C20.6961809,22.6871153 17.4786145,22.9344611 14.5386667,21.7998837 C14.029926,21.6054643 13.533337,21.3722507 13.0522917,21.1018359 C11.4250962,22.0190609 9.63246555,22.4947009 7.81570833,22.4912623 C7.16510551,22.4842162 6.51607673,22.4173045 5.875,22.2911849 L5.875,44.7220845 C5.875,45.9498589 6.7517757,46.9451667 7.83333333,46.9451667 L19.5833333,46.9451667 L19.5833333,33.6066734 L27.4166667,33.6066734 L27.4166667,46.9451667 L39.1666667,46.9451667 C40.2482243,46.9451667 41.125,45.9498589 41.125,44.7220845 L41.125,22.2822926 C40.4887822,22.4116582 39.8442868,22.4815492 39.198,22.4912623 Z"
                                                                    />
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            {isPending &&
                                                <h4></h4>
                                            }
                                            {!isPending &&
                                                <span className="nav-link-text ms-1">{mapel.nama}</span>
                                            }
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link  active">
                                            <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                                <svg
                                                    width="12px"
                                                    height="12px"
                                                    viewBox="0 0 42 42"
                                                    version="1.1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                >
                                                    <title>box-3d-50</title>
                                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                        <g
                                                            transform="translate(-2319.000000, -291.000000)"
                                                            fill="#FFFFFF"
                                                            fillRule="nonzero"
                                                        >
                                                            <g transform="translate(1716.000000, 291.000000)">
                                                                <g transform="translate(603.000000, 0.000000)">
                                                                    <path
                                                                        className="color-background"
                                                                        d="M22.7597136,19.3090182 L38.8987031,11.2395234 C39.3926816,10.9925342 39.592906,10.3918611 39.3459167,9.89788265 C39.249157,9.70436312 39.0922432,9.5474453 38.8987261,9.45068056 L20.2741875,0.1378125 L20.2741875,0.1378125 C19.905375,-0.04725 19.469625,-0.04725 19.0995,0.1378125 L3.1011696,8.13815822 C2.60720568,8.38517662 2.40701679,8.98586148 2.6540352,9.4798254 C2.75080129,9.67332903 2.90771305,9.83023153 3.10122239,9.9269862 L21.8652864,19.3090182 C22.1468139,19.4497819 22.4781861,19.4497819 22.7597136,19.3090182 Z"
                                                                    />
                                                                    <path
                                                                        className="color-background opacity-6"
                                                                        d="M23.625,22.429159 L23.625,39.8805372 C23.625,40.4328219 24.0727153,40.8805372 24.625,40.8805372 C24.7802551,40.8805372 24.9333778,40.8443874 25.0722402,40.7749511 L41.2741875,32.673375 L41.2741875,32.673375 C41.719125,32.4515625 42,31.9974375 42,31.5 L42,14.241659 C42,13.6893742 41.5522847,13.241659 41,13.241659 C40.8447549,13.241659 40.6916418,13.2778041 40.5527864,13.3472318 L24.1777864,21.5347318 C23.8390024,21.7041238 23.625,22.0503869 23.625,22.429159 Z"
                                                                    />
                                                                    <path
                                                                        className="color-background opacity-6"
                                                                        d="M20.4472136,21.5347318 L1.4472136,12.0347318 C0.953235098,11.7877425 0.352562058,11.9879669 0.105572809,12.4819454 C0.0361450918,12.6208008 6.47121774e-16,12.7739139 0,12.929159 L0,30.1875 L0,30.1875 C0,30.6849375 0.280875,31.1390625 0.7258125,31.3621875 L19.5528096,40.7750766 C20.0467945,41.0220531 20.6474623,40.8218132 20.8944388,40.3278283 C20.963859,40.1889789 21,40.0358742 21,39.8806379 L21,22.429159 C21,22.0503869 20.7859976,21.7041238 20.4472136,21.5347318 Z"
                                                                    />
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            <span className="nav-link-text ms-1">Materi</span>
                                        </a>
                                    </li>
                                    {!isPending &&
                                        mapel.materi_data.map(materi => {
                                            return (
                                                <li className="nav-item" key={materi.id}>
                                                    {materi.id == id ?
                                                        <Link className="nav-link active">
                                                            <span className="nav-link-text ms-1" style={{ marginRight: 'auto' }}>{materi.nama}</span>
                                                            {materi.materi_done_data.length > 0 ? <FontAwesomeIcon icon={faCheckCircle} className="text-success" /> : ""}
                                                        </Link>
                                                        :
                                                        <a href="#" onClick={() => changePage(materi.id)} className="nav-link">
                                                            <span className="nav-link-text ms-1" style={{ marginRight: 'auto' }}>{materi.nama}</span>
                                                            {materi.materi_done_data.length > 0 ? <FontAwesomeIcon icon={faCheckCircle} className="text-success" /> : ""}
                                                        </a>
                                                    }
                                                </li>
                                            )
                                        })
                                    }
                                    <li className="nav-item">
                                        <a className="nav-link  active">
                                            <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                                <svg
                                                    width="12px"
                                                    height="12px"
                                                    viewBox="0 0 40 44"
                                                    version="1.1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                >
                                                    <title>document</title>
                                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                        <g
                                                            transform="translate(-1870.000000, -591.000000)"
                                                            fill="#FFFFFF"
                                                            fillRule="nonzero"
                                                        >
                                                            <g transform="translate(1716.000000, 291.000000)">
                                                                <g transform="translate(154.000000, 300.000000)">
                                                                    <path
                                                                        className="color-background opacity-6"
                                                                        d="M40,40 L36.3636364,40 L36.3636364,3.63636364 L5.45454545,3.63636364 L5.45454545,0 L38.1818182,0 C39.1854545,0 40,0.814545455 40,1.81818182 L40,40 Z"
                                                                    />
                                                                    <path
                                                                        className="color-background"
                                                                        d="M30.9090909,7.27272727 L1.81818182,7.27272727 C0.814545455,7.27272727 0,8.08727273 0,9.09090909 L0,41.8181818 C0,42.8218182 0.814545455,43.6363636 1.81818182,43.6363636 L30.9090909,43.6363636 C31.9127273,43.6363636 32.7272727,42.8218182 32.7272727,41.8181818 L32.7272727,9.09090909 C32.7272727,8.08727273 31.9127273,7.27272727 30.9090909,7.27272727 Z M18.1818182,34.5454545 L7.27272727,34.5454545 L7.27272727,30.9090909 L18.1818182,30.9090909 L18.1818182,34.5454545 Z M25.4545455,27.2727273 L7.27272727,27.2727273 L7.27272727,23.6363636 L25.4545455,23.6363636 L25.4545455,27.2727273 Z M25.4545455,20 L7.27272727,20 L7.27272727,16.3636364 L25.4545455,16.3636364 L25.4545455,20 Z"
                                                                    />
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>

                                            <span className="nav-link-text ms-1">Tugas</span>
                                        </a>
                                    </li>
                                    {isPending &&
                                        <h4></h4>
                                    }
                                    {!isPending &&
                                        mapel.tugas_data.map(tugas => {
                                            return (
                                                <li className="nav-item">
                                                    <Link to={`/kelas/materi/tugas/${tugas.id}`} className="nav-link">
                                                        <span className="nav-link-text ms-1">{tugas.judul}</span>
                                                    </Link>
                                                </li>
                                            )
                                        })
                                    }
                                    <li className="nav-item">
                                        <a className="nav-link  active">
                                            <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                                <svg
                                                    width="12px"
                                                    height="12px"
                                                    viewBox="0 0 42 42"
                                                    version="1.1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                >
                                                    <title>office</title>
                                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                        <g
                                                            transform="translate(-1869.000000, -293.000000)"
                                                            fill="#FFFFFF"
                                                            fillRule="nonzero"
                                                        >
                                                            <g transform="translate(1716.000000, 291.000000)">
                                                                <g id="office" transform="translate(153.000000, 2.000000)">
                                                                    <path
                                                                        className="color-background opacity-6"
                                                                        d="M12.25,17.5 L8.75,17.5 L8.75,1.75 C8.75,0.78225 9.53225,0 10.5,0 L31.5,0 C32.46775,0 33.25,0.78225 33.25,1.75 L33.25,12.25 L29.75,12.25 L29.75,3.5 L12.25,3.5 L12.25,17.5 Z"
                                                                    />
                                                                    <path
                                                                        className="color-background"
                                                                        d="M40.25,14 L24.5,14 C23.53225,14 22.75,14.78225 22.75,15.75 L22.75,38.5 L19.25,38.5 L19.25,22.75 C19.25,21.78225 18.46775,21 17.5,21 L1.75,21 C0.78225,21 0,21.78225 0,22.75 L0,40.25 C0,41.21775 0.78225,42 1.75,42 L40.25,42 C41.21775,42 42,41.21775 42,40.25 L42,15.75 C42,14.78225 41.21775,14 40.25,14 Z M12.25,36.75 L7,36.75 L7,33.25 L12.25,33.25 L12.25,36.75 Z M12.25,29.75 L7,29.75 L7,26.25 L12.25,26.25 L12.25,29.75 Z M35,36.75 L29.75,36.75 L29.75,33.25 L35,33.25 L35,36.75 Z M35,29.75 L29.75,29.75 L29.75,26.25 L35,26.25 L35,29.75 Z M35,22.75 L29.75,22.75 L29.75,19.25 L35,19.25 L35,22.75 Z"
                                                                    />
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            <span className="nav-link-text ms-1">Quiz</span>
                                        </a>
                                    </li>
                                    {!isLock &&
                                        mapel.quiz_data != null ?
                                        <li className="nav-item">
                                            <Link to={`/kelas/materi/quiz/${mapel.id}`} className="nav-link">
                                                <span className="nav-link-text ms-1">{mapel.quiz_data.nama}</span>
                                            </Link>
                                        </li>
                                        :
                                        ""
                                    }
                                    {isLock &&
                                        mapel.quiz_data != null ?
                                        <li className="nav-item">
                                            <div className="nav-link">
                                                <span className="nav-link-text ms-1" style={{ marginRight: 'auto' }}>{mapel.quiz_data.nama}</span>
                                                <FontAwesomeIcon icon={faLock} className="text-secondary" />
                                            </div>
                                        </li>
                                        :
                                        ""
                                    }
                                </ul>
                            </div>
                        </aside>
                    </React.Fragment>
                    <main class="main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg">
                        <Navbar2 />
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10"></div>
                                <div className="col-md-2">

                                    {!lastMateri ?
                                        materi.materi_done_data.length > 0 ?
                                            <button onClick={() => changePage(currentIdx)} className="mt-4 btn btn-success rounded-pill">
                                                Next Materi <FontAwesomeIcon icon={faCheckCircle} />
                                            </button>
                                            :
                                            <button onClick={() => nextPage()} className="mt-4 btn btn-primary rounded-pill">
                                                Next Materi
                                            </button>
                                        :
                                        <button onClick={() => finish()} className="mt-4 btn btn-primary">
                                            Selesai
                                        </button>
                                    }
                                </div>
                            </div>
                            {materi.video != null ?
                                <div style={{ margin: '3% 20%' }}>
                                    <ReactPlayer url={materi.video} controls />
                                </div>
                                :
                                <div style={{ margin: '3% 2%' }}>
                                    <p>{materi.ket}</p>
                                    <a href={materi.file} style={{ textDecoration: 'none', color: '#04428d' }}>
                                        Download Materi
                                    </a>
                                </div>
                            }
                        </div>
                        <br />
                    </main>
                </>
            }
        </React.Fragment>
    );
}

export default VideoPlayer;