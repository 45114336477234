import React, { useEffect, useState } from "react";
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'
import axios from 'axios';
import { useHistory } from "react-router";
import { HalfMalf }
    from 'react-spinner-animated';

const Checkout = () => {
    const url = "https://lbw-edunesia.com/api-lbw/public/";
    // const url = "http://localhost:8000/";
    const [buy, setBuy] = useState([])
    const [isPending, setIsPending] = useState(true);
    const [auth, setAuth] = useState([]);
    const history = useHistory();
    const [kode, setKode] = useState([]);
    const [voucher, setVoucher] = useState("");
    const [potongan, setPotongan] = useState(0)

    function snap(idTransaction, disc) {
        axios.get(url + 'api/snap?id=' + idTransaction + '&potongan=' + disc)
            .then((response) => {
                window.snap.pay(response.data, {
                    onSuccess: function (result) {
                        /* You may add your own implementation here */
                        alert("payment success!"); console.log(result);
                    },
                    onPending: function (result) {
                        /* You may add your own implementation here */
                        // add attribute
                        result.id = idTransaction
                        result.voucher = potongan > 0 ? kode : ""
                        result.potongan = potongan
                        // post data
                        axios.post(url + 'api/status', result)
                            .then((response) => {
                                console.log("sukses")
                                history.push('/transaction')
                            })
                            .catch((error) => {
                                console.log(error)
                            })
                    },
                    onError: function (result) {
                        /* You may add your own implementation here */
                        alert("payment failed!");
                    },
                    onClose: function () {
                        /* You may add your own implementation here */
                        alert('Kamu menutup pop up pembayaran sebelum menyelesaikan pembayaran');
                    }
                })
            }).catch(err => console.log(err))
    }

    function checkVoucher() {
        axios.get(url + 'api/check-voucher?kode=' + kode + '&email=' + auth.email)
            .then((response) => {
                //set response user to state
                setVoucher(response.data.data)
                alert(response.data.meta.message);
                let diskon = response.data.data.potongan/100 * buy.gross_amount
                if(diskon > response.data.data.max_potongan){
                    setPotongan(response.data.data.max_potongan)
                }else{
                    setPotongan(diskon)
                }
            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        // set header
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`
        //fetch user from Rest API
        axios.get(url + 'api/user')
            .then((response) => {
                //set response user to state
                if (response.data.status == "Token is Expired") {
                    localStorage.removeItem("token");
                    history.push('/login');
                }
                setAuth(response.data.user);
                //fetch transaction from Rest API
                axios.get(url + 'api/transaction?last_user=' + response.data.user.id)
                    .then((response) => {
                        //set response user to state
                        setBuy(response.data.data)
                        setIsPending(false);
                    }).catch(err => {
                        console.log(err)
                        history.push(`/module`);
                    })
            }).catch(err => console.log(err))
    }, [setBuy])

    return (
        <React.Fragment>

            {isPending &&
                <HalfMalf text={"Loading..."} bgColor={"#f8f9fa"} center={true} width={"150px"} height={"150px"} />
            }
            {!isPending &&
                <>
                    <Navbar />
                    <div class="contact-area pt-100 pb-70">
                        <div class="container contact-width">
                            <div class="row">

                                <div class="col-lg-4 col-sm-6 text-center">
                                    {buy.status == "checkout" ?
                                        buy.transaction_detail.map((item) => {
                                            return (
                                                <div className="portfolio-item" >
                                                    <div className="portfolio-img">
                                                        <a href="portfolio.html">
                                                            <img src={item.module_data.image} alt="Portfolio Images" style={{ height: '250px' }} />
                                                        </a>
                                                        <div className="portfolio-content" style={{ marginBottom: '-18px' }}>
                                                            <p style={{ lineHeight: '3px' }}>{item.module_data.nama}</p>
                                                            <p style={{ lineHeight: '3px' }}>{item.nama_paket}</p>
                                                            <p style={{ lineHeight: '3px', marginBottom: '0px' }}>Rp. {parseInt(item.harga_paket).toLocaleString({})}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })

                                        :
                                        ""
                                    }

                                </div>

                                <div class="col-lg-8 col-sm-6 offset-sm-3 offset-lg-0">
                                    <div class="contact-card" style={{ textAlign: 'left' }}>
                                        <div class="row">
                                            <div className="col-md-8">
                                                <div class="form-group">
                                                    <input type="text" name="voucher" id="voucher" class="form-control" placeholder="Masukkan kode promo" onChange={(e) => setKode(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <button type="submit" class="btn btn-primary rounded-pill" onClick={checkVoucher}>
                                                    Gunakan
                                                </button>
                                            </div>
                                        </div>
                                        <h4>Payment Detail</h4>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <p>Harga</p>
                                                <p>Diskon</p>
                                                <p><b>Total</b></p>
                                            </div>
                                            <div className="col-md-6">
                                                <p style={{ textAlign: 'right', marginRight: '50px' }}><b>Rp. {parseInt(buy.gross_amount).toLocaleString()}</b></p>
                                                <p style={{ textAlign: 'right', marginRight: '50px' }}><b>Rp. {parseInt(potongan).toLocaleString()}</b></p>
                                                <p style={{ textAlign: 'right', marginRight: '50px' }}><b>Rp. {parseInt(buy.gross_amount - potongan).toLocaleString()}</b></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-10"></div>
                                        <div className="col-md-2">
                                            <button style={{ textAlign: 'right', marginRight: '50px' }} onClick={() => snap(buy.id, potongan)} className="btn btn-primary rounded-pill">Bayar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </>
            }
        </React.Fragment>
    );
}

export default Checkout;