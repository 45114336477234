import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useHistory } from 'react-router';
import axios from 'axios';
import Navbar2 from "../../components/Navbar2"
import Logo from '../../lbw-logo.png'
import { HalfMalf }
    from 'react-spinner-animated';
import ReactStars from "react-rating-stars-component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";

const Kelas = () => {
    const url = "https://lbw-edunesia.com/api-lbw/public/";
    // const url = "http://localhost:8000/";
    const [kelas, setKelas] = useState([])
    const history = useHistory();
    const [auth, setAuth] = useState([]);
    const [isPending, setIsPending] = useState(true)
    const [rating, setRating] = useState(0)
    const [idModule, setIdModule] = useState(0)
    const [pesan, setPesan] = useState("")
    const [testi, setTesti] = useState([])

    const ratingChanged = (newRating) => {
        setRating(newRating)
    };

    async function testimoni(e) {
        e.preventDefault();
        // get date
        const current2 = new Date();
        const date2 = `${current2.getFullYear()}-${current2.getMonth() + 1}-${current2.getDate()}`;
        let testi = {
            id_user: auth.id,
            id_module: idModule,
            tanggal: date2,
            pesan: pesan,
            bintang: rating,
        }

        // post data
        await axios.post(url + 'api/testimoni', testi)
            .then((response) => {
                window.location.reload();
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        if (!localStorage.getItem("token")) {
            history.push('/');
        }
        // set header
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`
        //fetch user from Rest API
        axios.get(url + 'api/user')
            .then((response) => {
                //set response user to state
                if (response.data.status == "Token is Expired") {
                    localStorage.removeItem("token");
                    history.push('/login');
                }
                setAuth(response.data.user);
                //fetch transaction from Rest API
                axios.get(url + 'api/subscription?id_user=' + response.data.user.id)
                    .then((response) => {
                        //set response user to state
                        axios.get(url + 'api/testimoni?id_user=' + response.data.data.id_user)
                            .then((res) => {
                                response.data.data.map((subs)=>{
                                    let check = res.data.data.find(element => element.id_module === subs.id_module)
                                    if(check){
                                        subs.status = true
                                    }else{
                                        subs.status = false
                                    }
                                })
                                setTesti(res.data.data)
                                setKelas(response.data.data)
                            }).catch(err => console.log(err))
                        setIsPending(false)
                    }).catch(err => console.log(err))
            }).catch(err => {
                console.log(err)
            })
    }, [setIsPending])
    
    return (
        <React.Fragment>
            {isPending &&
                <HalfMalf text={"Loading..."} bgColor={"#f8f9fa"} center={true} width={"150px"} height={"150px"} />
            }
            {!isPending &&
                <>
                    <React.Fragment>
                        <aside
                            className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 "
                            id="sidenav-main"
                        >
                            <div className="sidenav-header">
                                <i
                                    className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
                                    aria-hidden="true"
                                    id="iconSidenav"
                                />
                                <Link
                                    className="navbar-brand m-0"
                                    to="/"
                                    target="_blank"
                                >
                                    <img
                                        src={Logo}
                                        className="navbar-brand-img h-100"
                                        alt="main_logo"
                                    />
                                    <span className="ms-1 font-weight-bold">Little Bookworm</span>
                                </Link>
                            </div>
                            <hr className="horizontal dark mt-0" />
                            <div
                                className="collapse navbar-collapse  w-auto  max-height-vh-100 h-100"
                                id="sidenav-collapse-main"
                            >
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <a className="nav-link  active">
                                            <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                                <svg
                                                    width="12px"
                                                    height="12px"
                                                    viewBox="0 0 45 40"
                                                    version="1.1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                >
                                                    <title>shop </title>
                                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                        <g
                                                            transform="translate(-1716.000000, -439.000000)"
                                                            fill="#FFFFFF"
                                                            fillRule="nonzero"
                                                        >
                                                            <g transform="translate(1716.000000, 291.000000)">
                                                                <g transform="translate(0.000000, 148.000000)">
                                                                    <path
                                                                        className="color-background opacity-6"
                                                                        d="M46.7199583,10.7414583 L40.8449583,0.949791667 C40.4909749,0.360605034 39.8540131,0 39.1666667,0 L7.83333333,0 C7.1459869,0 6.50902508,0.360605034 6.15504167,0.949791667 L0.280041667,10.7414583 C0.0969176761,11.0460037 -1.23209662e-05,11.3946378 -1.23209662e-05,11.75 C-0.00758042603,16.0663731 3.48367543,19.5725301 7.80004167,19.5833333 L7.81570833,19.5833333 C9.75003686,19.5882688 11.6168794,18.8726691 13.0522917,17.5760417 C16.0171492,20.2556967 20.5292675,20.2556967 23.494125,17.5760417 C26.4604562,20.2616016 30.9794188,20.2616016 33.94575,17.5760417 C36.2421905,19.6477597 39.5441143,20.1708521 42.3684437,18.9103691 C45.1927731,17.649886 47.0084685,14.8428276 47.0000295,11.75 C47.0000295,11.3946378 46.9030823,11.0460037 46.7199583,10.7414583 Z"
                                                                    />
                                                                    <path
                                                                        className="color-background"
                                                                        d="M39.198,22.4912623 C37.3776246,22.4928106 35.5817531,22.0149171 33.951625,21.0951667 L33.92225,21.1107282 C31.1430221,22.6838032 27.9255001,22.9318916 24.9844167,21.7998837 C24.4750389,21.605469 23.9777983,21.3722567 23.4960833,21.1018359 L23.4745417,21.1129513 C20.6961809,22.6871153 17.4786145,22.9344611 14.5386667,21.7998837 C14.029926,21.6054643 13.533337,21.3722507 13.0522917,21.1018359 C11.4250962,22.0190609 9.63246555,22.4947009 7.81570833,22.4912623 C7.16510551,22.4842162 6.51607673,22.4173045 5.875,22.2911849 L5.875,44.7220845 C5.875,45.9498589 6.7517757,46.9451667 7.83333333,46.9451667 L19.5833333,46.9451667 L19.5833333,33.6066734 L27.4166667,33.6066734 L27.4166667,46.9451667 L39.1666667,46.9451667 C40.2482243,46.9451667 41.125,45.9498589 41.125,44.7220845 L41.125,22.2822926 C40.4887822,22.4116582 39.8442868,22.4815492 39.198,22.4912623 Z"
                                                                    />
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            <span className="nav-link-text ms-1">Kelas Saya</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </aside>
                    </React.Fragment>
                    <main class="main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg ">
                        <Navbar2 />
                        <div className="container-fluid py-4">
                            <div className="row mt-4">
                                {!kelas &&
                                    <p>Loading...</p>
                                }
                                {kelas &&
                                    kelas.map((sub) => {
                                        const current = new Date(sub.tanggal)
                                        const current2 = new Date()
                                        let Difference_In_Time = current2.getTime() - current.getTime();
                                        let Difference_In_Days = Math.floor(Difference_In_Time / (1000 * 3600 * 24));
                                        let bulan = 365 / 12;
                                        let masa_aktif = Math.ceil((sub.durasi * bulan) - Difference_In_Days);
                                        return (
                                            <div className="col-lg-4 mb-lg-0 mb-4" key={sub.id}>
                                                <div className="card z-index-2">
                                                    {/* here */}
                                                    {masa_aktif > 0 ?
                                                        <div className="card-body p-3">
                                                            <div className="bg-gradient-dark border-radius-lg mb-3">
                                                                <div className="chart">
                                                                    <Link to={`kelas/${sub.module_data.id}`}>
                                                                        <img src={sub.module_data.image} alt="Member Images" width="475" height="230" />
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                            <Link to={`kelas/${sub.module_data.id}`}>
                                                                <h6 className="ms-2 mt-4 mb-0"> {sub.module_data.nama} </h6>
                                                            </Link>
                                                            <p className="text-sm ms-2">
                                                                {" "}
                                                                <span className="font-weight-bolder">Masa Aktif {sub.durasi} Bulan</span><br />
                                                                {/* <span className="font-weight-bolder">Rp. {parseInt(sub.harga_paket).toLocaleString(undefined, {})}</span><br /> */}
                                                                <Link to={`kelas/${sub.module_data.id}`}>
                                                                    <span className="btn btn-success btn-sm rounded-pill text-white px-3 py-2 mt-2 mr-4">Sisa {masa_aktif} Hari Lagi</span>
                                                                </Link>
                                                                {!sub.status &&
                                                                    <button onClick={() => setIdModule(sub.module_data.id)} data-bs-toggle="modal" data-bs-target="#exampleModal" className="btn btn-warning btn-sm rounded-pill text-white px-3 py-2 mt-2">
                                                                    Beri Rating
                                                                    </button>
                                                                }
                                                            </p>
                                                        </div>

                                                        :

                                                        <div className="card-body p-3">
                                                            <div className="bg-gradient-dark border-radius-lg mb-3">
                                                                <div className="chart">
                                                                    <Link to={`/module/${sub.module_data.id}`}>
                                                                        <img src={sub.module_data.image} alt="Member Images" width="475" height="230" />
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                            <Link to={`/module/${sub.module_data.id}`}>
                                                                <h6 className="ms-2 mt-4 mb-0"> {sub.module_data.nama} </h6>
                                                            </Link>
                                                            <p className="text-sm ms-2">
                                                                {" "}
                                                                <span className="font-weight-bolder">Masa Aktif {sub.durasi} Bulan</span><br />
                                                                {/* <span className="font-weight-bolder">Rp. {parseInt(sub.harga_paket).toLocaleString(undefined, {})}</span><br /> */}
                                                                <Link to={`/module/${sub.module_data.id}`}>
                                                                    <span className="btn btn-danger btn-sm rounded-pill text-white px-3 py-2 mt-2">Expired</span>
                                                                </Link>
                                                            </p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </main>
                </>
            }
            {/* Modal */}
            <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Testimoni</h5>
                            <button type="button" className="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div class="form-group">
                                <label for="exampleFormControlTextarea1">Rating</label>
                                <div className="row">
                                    <div className="col-md-4">
                                        <ReactStars
                                            count={5}
                                            onChange={ratingChanged}
                                            size={24}
                                            isHalf={true}
                                            emptyIcon={<FontAwesomeIcon icon={faStar} />}
                                            halfIcon={<FontAwesomeIcon icon={faStarHalfAlt} />}
                                            fullIcon={<FontAwesomeIcon icon={faStar} />}
                                            activeColor="#ffd700"
                                        />
                                    </div>
                                    <div className="col-md-1" style={{ marginTop: '9px', marginBottom: '-70px', marginLeft: '-50px' }}>
                                        <p>({rating})</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="exampleFormControlTextarea1">Testimoni</label>
                                <textarea onChange={(e) => setPesan(e.target.value)} class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn bg-gradient-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" onClick={testimoni} className="btn bg-gradient-dark">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Kelas;