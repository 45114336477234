import React from "react";
import { Link } from 'react-router-dom'

const Navbar2 = () => {
    return (
        <React.Fragment>
            <>
                {/* Navbar */}
                <nav
                    className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
                    id="navbarBlur"
                    navbar-scroll="true"
                >
                    <div className="container-fluid py-1 px-3">
                        <nav aria-label="breadcrumb">
                            <Link to="/kelas">
                                <h6 className="font-weight-bolder mb-0 links">Kelas Dashboard</h6>
                            </Link>
                        </nav>
                        <div
                            className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4"
                            id="navbar"
                        >
                            <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                                <Link to='/' style={{color:'black',fontWeight:'bold'}}>
                                    <h6 className="links">Home</h6>
                                </Link>
                            </div>
                            <ul className="navbar-nav  justify-content-end">
                                <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
                                    <a
                                        href="javascript:;"
                                        className="nav-link text-body p-0"
                                        id="iconNavbarSidenav"
                                    >
                                        Home
                                    </a>
                                </li>
                                <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
                                    <a
                                        href="javascript:;"
                                        className="nav-link text-body p-0"
                                        id="iconNavbarSidenav"
                                    >
                                        <div className="sidenav-toggler-inner">
                                            <i className="sidenav-toggler-line" />
                                            <i className="sidenav-toggler-line" />
                                            <i className="sidenav-toggler-line" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item dropdown pe-2 d-flex align-items-center">
                                    <a
                                        href="javascript:;"
                                        className="nav-link text-body p-0"
                                        id="dropdownMenuButton"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <i className="fa fa-cog fixed-plugin-button-nav cursor-pointer" />
                                    </a>
                                    <ul
                                        className="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4"
                                        aria-labelledby="dropdownMenuButton"
                                    >
                                        <li className="mb-2">
                                            <a
                                                className="dropdown-item border-radius-md"
                                                href="javascript:;"
                                            >
                                                <Link to='/profile'>
                                                    Profile
                                                </Link>
                                            </a>
                                            <a
                                                className="dropdown-item border-radius-md"
                                                href="javascript:;"
                                            >
                                                <Link to='/logout'>
                                                    Logout
                                                </Link>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </>

        </React.Fragment>
    );
}

export default Navbar2;