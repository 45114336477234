import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { useHistory } from 'react-router';
import { HalfMalf }
    from 'react-spinner-animated';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";

const Start = () => {
    const url = "https://lbw-edunesia.com/api-lbw/public/";
    // const url = "http://localhost:8000/";
    const [historyQuiz, setHistoryQuiz] = useState([]);
    const [isPending, setIsPending] = useState(true);
    const { id } = useParams();
    const [auth, setAuth] = useState([]);
    const [mapel, setMapel] = useState([]);
    const history = useHistory();

    useEffect(() => {
        if (!localStorage.getItem("token")) {
            history.push('/');
        }
        axios.get(url + 'api/mapel?id=' + id)
            .then((response) => {
                //set response user to state
                setMapel(response.data.data)
            }).catch(err => console.log(err))
        // set header
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`
        // fetch data user
        axios.get(url + 'api/user')
            .then((response) => {
                //set response user to state
                if (response.data.status == "Token is Expired") {
                    localStorage.removeItem("token");
                    history.push('/login');
                }
                setAuth(response.data.user);
                axios.get(url + 'api/history-quiz?id_user=' + response.data.user.id + '&id_mapel=' + id)
                    .then((response) => {
                        //set response user to state
                        setHistoryQuiz(response.data.data)
                        setIsPending(false);
                    }).catch(err => console.log(err))
            }).catch(err => console.log(err))
    }, [setIsPending])
    // console.log(historyQuiz)
    return (
        <React.Fragment>
            {isPending &&
                <HalfMalf text={"Loading..."} bgColor={"#f8f9fa"} center={true} width={"150px"} height={"150px"} />
            }
            {!isPending &&
                <div className="container">
                    <Link to={`/kelas/materi/${id}`} className="pt-2 text-sm btn bg-gradient-dark" style={{ marginTop: '30px', marginbottom: '-50px' }}><FontAwesomeIcon icon={faArrowAltCircleLeft} /></Link>
                    <main class="main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card" style={{ margin: '80px 0', borderRadius: '20px' }}>
                                    <div className="card-body">
                                        <div style={{ width: '500px', margin: '50px auto 0px auto' }}>
                                            <h5>Petunjuk pengerjaan:</h5>
                                            <h5>1. Pastikan internet anda stabil</h5>
                                            <h5>2. Jangan tutup website ketika anda masih dalam proses pengerjaan</h5>
                                            <h5>3. Pastikan menekan tombol "finish" saat sudah selesai mengejakan soal</h5>
                                            <h5>4. Jika waktu habis, pastikan menekan tombol "finish"</h5>
                                            <h5>5. Setelah melakukan quiz maka akan ditampilkan nilai yang anda peroleh pada quiz tersebut, beserta jawaban</h5>
                                        </div>
                                        <div style={{ textAlign: 'center' }}>
                                            <Link to={`/kelas/materi/quiz/start/${id}/${mapel.quiz_data.durasi}`} class="btn bg-gradient-dark">START</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row" style={{ margin: '80px 0' }}>
                                    <div className="col-12">
                                        <div className="card mb-4">
                                            <div className="card-header pb-0">
                                                <h6>Hasil Quiz</h6>
                                            </div>
                                            <div className="card-body px-0 pt-0 pb-2">
                                                <div className="table-responsive p-0">
                                                    <table className="table align-items-center justify-content-center mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th className="text-uppercase text-secondary text-xs font-weight-bolder opacity-7 text-left">No</th>
                                                                <th className="text-uppercase text-secondary text-xs font-weight-bolder opacity-7 text-left">Tanggal</th>
                                                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nilai</th>
                                                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th>
                                                                <th />
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {historyQuiz.length == 0 ?
                                                                <tr>
                                                                    <td>Data kosong</td>
                                                                </tr>
                                                                :
                                                                historyQuiz.map((item, index) => {
                                                                    return (
                                                                        <tr key={item.id}>
                                                                            <td>
                                                                                <p className="text-sm font-weight-bold mb-0 ml-3">{index + 1}</p>
                                                                            </td>
                                                                            <td>
                                                                                <p className="text-sm font-weight-bold mb-0 ml-3">{item.tanggal}</p>
                                                                            </td>
                                                                            <td>
                                                                                <span className="text-sm font-weight-bold">{item.nilai}</span>
                                                                            </td>
                                                                            <td>
                                                                                <div className="ml-3">
                                                                                    <Link to={`/kelas/materi/quiz/summary/${item.id}`} className="pt-2 text-sm btn bg-gradient-dark">View History</Link>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            }
        </React.Fragment>
    );
}

export default Start;