import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
//import hook react
import React, { useState, useEffect, useContext } from 'react';

//import hook useHitory from react router dom
import { useHistory } from 'react-router';

//import axios
import axios from 'axios';
import { AuthContext } from '../AuthContext';

import Logo from '../img/lbw-logo.png'
import LogoText from '../img/lbw-text-color.png'
const Navbar = () => {
    const url = "https://lbw-edunesia.com/api-lbw/public/";
    // const url = "http://localhost:8000/";
    //state user
    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext)
    const [category, setCategory] = useState([]);
    const [isPending, setIsPending] = useState(true);
    const [frontend, setFrontend] = useState([]);
    //define history
    const history = useHistory();

    // //token
    const token = localStorage.getItem("token");

    //function logout
    // const logoutHanlder = async () => {

    //     //set axios header dengan type Authorization + Bearer token
    //     axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    //     //fetch Rest API
    //     await axios.post('http://localhost:8000/api/logout')
    //     .then(() => {

    //         //remove token from localStorage
    //         localStorage.removeItem("token");
    //         setIsAuthenticated(false)
    //         //redirect halaman login
    //         history.push('/login');
    //     });
    // };
    useEffect(() => {
        if (localStorage.getItem("token")) {
            setIsAuthenticated(true)
        }

        axios.get(url + 'api/frontend')
            .then((response) => {
                //set response user to state
                setFrontend(response.data.data)
                // setIsFetch(true)
            }).catch(err => console.log(err))

        axios.get(url + 'api/category')
            .then((response) => {
                //set response user to state
                setCategory(response.data.data)
                setIsPending(false)
                // setIsFetch(true)
            }).catch(err => console.log(err))
    }, [setCategory])

    const changePage = (destId) => {
        history.push(`/module-category/${destId}`)
        window.location.reload();
    }
    return (
        <div className="navbar-area">
            <div className="mobile-nav">
                <a href="/" className="logo">
                    <img src={frontend.logo} alt="Logo" height={46} />
                    <img src={frontend.logo_text} alt="Logo" height={46} />
                </a>
            </div>
            <div className="main-nav top-nav">
                <div className="container">
                    <nav className="navbar navbar-expand-md navbar-lights ">
                        <a className="navbar-brand" href="/">
                            <img src={Logo} alt="Logo" height={46} style={{ marginRight: '5px' }} />
                            <img src={LogoText} alt="Logo" height={46} style={{ marginTop: '10px' }} />
                        </a>

                        <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto">
                                {window.location.pathname === "/" ?
                                    <li className="nav-item">
                                        <Link to="/" className="nav-link active">
                                            Beranda
                                        </Link>
                                    </li>
                                    :
                                    <li className="nav-item">
                                        <Link to="/" className="nav-link">
                                            Beranda
                                        </Link>
                                    </li>
                                }
                                {window.location.pathname === "/module" ?
                                    <li className="nav-item">
                                        <Link to="/module" className="nav-link active">
                                            Module
                                        </Link>
                                    </li>
                                    :
                                    <li className="nav-item">
                                        <Link to="/module" className="nav-link">
                                            Module
                                        </Link>
                                    </li>
                                }
                                {window.location.pathname === "/contact" ?
                                    <li className="nav-item">
                                        <Link to="/contact" className="nav-link active">
                                            Kontak Kami
                                        </Link>
                                    </li>
                                    :
                                    <li className="nav-item">
                                        <Link to="/contact" className="nav-link">
                                            Kontak Kami
                                        </Link>
                                    </li>
                                }
                                {window.location.pathname === "/blog" ?
                                    <li className="nav-item">
                                        <Link to="/blog" className="nav-link active">
                                            Blog
                                        </Link>
                                    </li>
                                    :
                                    <li className="nav-item">
                                        <Link to="/blog" className="nav-link">
                                            Blog
                                        </Link>
                                    </li>
                                }
                                {isPending &&
                                    <li class="nav-item">
                                        <a href="#" class="nav-link">
                                            Kategori
                                            <i class='bx bxs-chevron-right'></i>
                                        </a>
                                    </li>
                                }
                                {!isPending &&
                                    <li class="nav-item">
                                        <a href="#" class="nav-link">
                                            Kategori
                                            <i class='bx bxs-chevron-right'></i>
                                        </a>
                                        <ul class="dropdown-menu">
                                            {category.map(item => {
                                                return (
                                                    <li class="nav-item">
                                                        <a onClick={() => changePage(item.id)} class="nav-link">
                                                            {item.category}
                                                        </a>
                                                    </li>
                                                )
                                            })}

                                        </ul>
                                    </li>
                                }
                            </ul>

                            <div className="other-side">
                                <ul className="navbar-nav m-auto">
                                    {isAuthenticated &&
                                        <li className="nav-item">
                                            <Link to="/module/pembayaran/1" className="nav-link">
                                                <FontAwesomeIcon icon={faShoppingCart} />
                                            </Link>
                                        </li>
                                    }
                                    <li className="nav-item">
                                        <a href="#" className="nav-link">
                                            <FontAwesomeIcon icon={faUserCircle} />
                                        </a>
                                        {!isAuthenticated ?
                                            <ul className="dropdown-menu">
                                                <li className="nav-item">
                                                    <Link to='/login'>
                                                        Login
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to='/register'>
                                                        Register
                                                    </Link>
                                                </li>
                                            </ul>
                                            :
                                            <ul className="dropdown-menu">
                                                <li className="nav-item">
                                                    <Link to='/profile'>
                                                        Profile
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to='/transaction'>
                                                        Transaction
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to='/kelas'>
                                                        Kelas Saya
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to='/change-password'>
                                                        Change Password
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to='/logout'>
                                                        Logout
                                                    </Link>
                                                </li>
                                            </ul>
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    );
}

export default Navbar;