import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import { AuthContext } from '../../AuthContext';
import { UserContext } from '../../UserContext';
import { Link } from 'react-router-dom';
//import axios
import axios from 'axios';
import Footer from '../../components/Header'
import Navbar from '../../components/Navbar'

const Login = () => {
    const url = "https://lbw-edunesia.com/api-lbw/public/";
    // const url = "http://localhost:8000/";
    // authcontext in app
    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext)
    const { user, setUser } = useContext(UserContext)
    //define state
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    //define state validation
    const [validation, setValidation] = useState([]);

    //define history
    const history = useHistory();

    // cek jika sudah login maka redirect ke dashboard
    useEffect(() => {

        //check token
        if (localStorage.getItem('token')) {

            //redirect page dashboard
            history.push('/');
        }
    }, []);


    //function "loginHanlder"
    const loginHandler = async (e) => {
        e.preventDefault();

        //initialize formData
        const formData = new FormData();

        //append data to formData
        formData.append('email', email);
        formData.append('password', password);

        //send data to server
        await axios.post(url+'api/login', formData)
            .then((response) => {

                //set token on localStorage
                localStorage.setItem('token', response.data.token);
                setIsAuthenticated(true)
                // set header
                axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`
                //fetch user from Rest API
                axios.get(url+'api/user')
                    .then((response) => {
                        //set response user to state
                        setUser(response.data.user);
                        history.push('/');
                    }).catch(err => console.log(err))
            })
            .catch((error) => {

                //assign error to state "validation"
                setValidation(error.response.data);
            })
    };

    return (
        <React.Fragment>
            <Navbar />
            <section className="sign-up-area ptb-100">
                <div className="container">
                    <div className="scetion-title text-center">
                        <span>Login</span>
                        <h2>Masuk ke Akun Anda!</h2>
                        {/* <p>
                            It is a long established fact that a reader will be distracted by
                            the readable content of a page when looking at its layout.
                        </p> */}
                    </div>
                    <div className="contact-wrap-form log-in-width">
                        {
                            validation.error && (
                                <div className="alert alert-danger text-white">
                                    {validation.error}
                                </div>
                            )
                        }
                        <form onSubmit={loginHandler}>
                            <div className="col-12">
                                <div className="form-group">
                                    <input className="form-control" type="email" onChange={(event) => setEmail(event.target.value)} value={email} placeholder="Email" />
                                </div>
                                {
                                    validation.email && (
                                        <div className="alert alert-danger">
                                            {validation.email[0]}
                                        </div>
                                    )
                                }
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <input className="form-control" type="password" onChange={(event) => setPassword(event.target.value)} value={password} placeholder="Password" />
                                </div>
                            </div>
                            {
                                validation.password && (
                                    <div className="alert alert-danger">
                                        {validation.password[0]}
                                    </div>
                                )
                            }
                            <div class="col-lg-6 col-sm-6" style={{marginLeft:'-95px'}}>
                                <Link class="forget" to="/change-password">Lupa Password?</Link>
                            </div>

                            <div class="col-12 text-center">
                                <button class="default-btn btn-two" type="submit">
                                    Login
                                </button>
                            </div>
                        </form>
                        <div class="col-12">
                            <p class="account-desc">
                                Belum punya akun?
                                <Link to="/register">Daftar</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    );
}

export default Login;