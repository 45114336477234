import React, { useEffect, useState, useContext } from "react";
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';
import 'react-owl-carousel2/src/owl.theme.default.css';
import "../../App.css";
import axios from 'axios';
import { Link } from "react-router-dom";
import { AuthContext } from '../../AuthContext';
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { HalfMalf }
    from 'react-spinner-animated';

const Home = () => {
    const url = "https://lbw-edunesia.com/api-lbw/public/";
    // const url = "http://localhost:8000/";
    const [module, setModule] = useState(null)
    const [category, setCategory] = useState(null)
    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext)
    const [isPending, setIsPending] = useState(true)
    const [blog, setBlog] = useState(null)
    const [frontend, setFrontend] = useState([]);

    useEffect(() => {
        if (localStorage.getItem("token")) {
            setIsAuthenticated(true)
        }
        //fetch category from Rest API
        axios.get(url + 'api/category')
            .then((response) => {
                //set response user to state
                setCategory(response.data.data)
                // setIsFetch(true)
            }).catch(err => console.log(err))

        axios.get(url + 'api/frontend')
            .then((response) => {
                //set response user to state
                setFrontend(response.data.data)
                // setIsFetch(true)
            }).catch(err => console.log(err))

        axios.get(url + 'api/blog-beranda')
            .then((response) => {
                //set response user to state
                setBlog(response.data.data)
                // setIsFetch(true)
            }).catch(err => console.log(err))

        //fetch module from Rest API
        axios.get(url + 'api/module-beranda')
            .then((response) => {
                //set response user to state
                setModule(response.data.data)
                setIsPending(false)
            }).catch(err => console.log(err))
    }, [setIsPending])

    const options = {
        items: 3,
    };

    const options2 = {
        items: 5,
        dots: false,
        autoplay: true,
        autoplayTimeout: 3000,
        loop: true,
    };

    return (
        <React.Fragment>
            {isPending &&
                <HalfMalf text={"Loading..."} bgColor={"#f8f9fa"} center={true} width={"150px"} height={"150px"} />
            }
            {!isPending &&
                <>
                    <Navbar />
                    <div className="banner-another">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="banner-text banner-ptb">
                                        <h1 className="head-width">{frontend.jumbotron_big_text}</h1>
                                        <p>
                                            {frontend.jumbotron_small_text}
                                        </p>
                                        <div className="banner-btn">
                                            <Link to="/register" className="default-btn">Daftar Sekarang</Link>
                                            <Link to="/module" className="default-btn active">Lihat Module</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="banner-img-3">
                                        <img src={frontend.jumbotron_image} alt="Banner Images" />
                                        <div className="banner-img-shape">
                                            <img src="assets/img/shape/shape-home3.png" alt="Banner Shape" />
                                        </div>
                                        <div className="banner-social-icon">
                                            <a href="https://www.facebook.com/littlebookwormonline/" className="banner-icon-tag banner-icon1">
                                                <img src="assets/img/shape/facebook.svg" alt="facebook" />
                                            </a>
                                            {/* <a href="#" className="banner-icon-tag banner-icon4">
                                        <img src="assets/img/shape/pinterest.svg" alt="Pinterest" />
                                    </a>
                                    <a href="#" className="banner-icon-tag banner-icon3">
                                        <img src="assets/img/shape/twitter.svg" alt="Twitter" />
                                    </a> */}
                                            <a href="https://www.instagram.com/littlebookwormonline/?hl=id" className="banner-icon-tag banner-icon2">
                                                <img src="assets/img/shape/instagram.svg" alt="Instagram" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="home-shape">
                            <div className="shape1">
                                <img src="assets/img/shape/1.png" alt="shape" />
                            </div>
                            <div className="shape2">
                                <img src="assets/img/shape/2.png" alt="shape" />
                            </div>
                            <div className="home-shape3">
                                <img src="assets/img/shape/3.png" alt="shape" />
                            </div>
                            <div className="home-shape4">
                                <img src="assets/img/shape/4.png" alt="shape" />
                            </div>
                            <div className="home-shape5">
                                <img src="assets/img/shape/2.png" alt="shape" />
                            </div>
                            <div className="home-shape6">
                                <img src="assets/img/shape/6.png" alt="shape" />
                            </div>
                            <div className="shape7">
                                <img src="assets/img/shape/3.png" alt="shape" />
                            </div>
                            <div className="home-shape8">
                                <img src="assets/img/shape/5.png" alt="shape" />
                            </div>
                        </div>
                    </div>

                    {/* Brand Area */}
                    <div className="brand-area">
                        <div className="container">
                            <div className="brand-title">
                                <span>Category</span>
                                <h2>{frontend.category_text}</h2>
                            </div>
                            <OwlCarousel options={options2}>
                                {category === null ?
                                    <p>Loading...</p>
                                    :
                                    category.map((kategori) => {
                                        return (
                                            <div className="brand-item" style={{ textAlign: 'center' }}>
                                                <a href="#">
                                                    <img src={kategori.icon} alt="Brand Images" height={75} style={{ marginBottom: '10px', maxWidth: '110px' }} />
                                                </a><br />
                                                <span>{kategori.category}</span>
                                            </div>
                                        )
                                    })
                                }
                            </OwlCarousel>
                        </div>
                    </div>
                    {/* Brand Area End */}

                    {/* Service Area */}
                    <section className="service-area pt-100">
                        <div className="container">
                            <div className="scetion-title text-center">
                                <span>Why Choose Us</span>
                                <h2>{frontend.choose_text}</h2>
                            </div>
                            <div className="row pt-45">
                                <div className="col-lg-4 col-sm-6">
                                    <div className="service-card">
                                        <a>
                                            <img src={url + 'storage/' + frontend.choose_card_1_img} alt="Images" />
                                        </a>
                                        <a>
                                            <h3>{frontend.choose_card_1_text}</h3>
                                        </a>
                                        <p>
                                            {frontend.choose_card_1_ket}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="service-card">
                                        <a>
                                            <img src={url + 'storage/' + frontend.choose_card_2_img} alt="Images" />
                                        </a>
                                        <a>
                                            <h3>{frontend.choose_card_2_text}</h3>
                                        </a>
                                        <p>
                                            {frontend.choose_card_2_ket}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="service-card">
                                        <a>
                                            <img src={url + 'storage/' + frontend.choose_card_3_img} alt="Images" />
                                        </a>
                                        <a>
                                            <h3>{frontend.choose_card_3_text}</h3>
                                        </a>
                                        <p>
                                            {frontend.choose_card_3_ket}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="service-card">
                                        <a>
                                            <img src={url + 'storage/' + frontend.choose_card_4_img} alt="Images" />
                                        </a>
                                        <a>
                                            <h3>{frontend.choose_card_4_text}</h3>
                                        </a>
                                        <p>
                                            {frontend.choose_card_4_ket}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="service-card">
                                        <a>
                                            <img src={url + 'storage/' + frontend.choose_card_5_img} alt="Images" />
                                        </a>
                                        <a>
                                            <h3>{frontend.choose_card_5_text}</h3>
                                        </a>
                                        <p>
                                            {frontend.choose_card_5_ket}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="service-card">
                                        <a>
                                            <img src={url + 'storage/' + frontend.choose_card_6_img} alt="Images" />
                                        </a>
                                        <a>
                                            <h3>{frontend.choose_card_6_text}</h3>
                                        </a>
                                        <p>
                                            {frontend.choose_card_6_ket}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="service-shape1">
                            <img src="assets/img/shape/right-side.png" alt="Shape Images" />
                        </div>
                    </section>
                    {/* Service Area End */}

                    {/* Portfolio Area */}
                    <section className="portfolio-area pt-100 pb-70">
                        <div className="container">
                            <div className="scetion-title text-center">
                                <span>Kelas</span>
                                <h2>{frontend.kelas_text}</h2>
                            </div>
                            <div className="row pt-45">
                                <OwlCarousel options={options}>
                                    {module === null ?
                                        <p>Loading...</p>
                                        :
                                        module.map((mod) => {
                                            return (
                                                <div className="portfolio-item" key={mod.id}>
                                                    <div className="portfolio-img">
                                                        <Link to={`module/${mod.id}`}>
                                                            <img src={mod.image} alt="Module Images" width={510} height={350} />
                                                        </Link>
                                                        <div className="portfolio-tag">
                                                            <Link to={`module/${mod.id}`}><span>{mod.category_data.category} (<FontAwesomeIcon icon={faStar} style={{ color: '#ffd700' }} /> {mod.rating})</span></Link>
                                                        </div>
                                                        <div className="portfolio-content">
                                                            {isAuthenticated &&
                                                                <Link to={`module/${mod.id}`}>
                                                                    <h3>{mod.nama}</h3>
                                                                    <i className="bx bxs-chevron-right" />
                                                                </Link>
                                                            }
                                                            {!isAuthenticated &&
                                                                <Link to="login">
                                                                    <h3>{mod.nama}</h3>
                                                                    <i className="bx bxs-chevron-right" />
                                                                </Link>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </OwlCarousel>
                            </div>
                        </div>
                    </section>

                    {/* About Area */}
                    <div className="about-area about-max ptb-70" style={{ marginLeft: '60px' }}>
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-lg-12">
                                    <div className="about-content about-width">
                                        <span>Get Your Dream</span>
                                        <h2>{frontend.get_big_text}</h2>
                                        <p>
                                            {frontend.get_small_text}
                                        </p>
                                        <div className="about-btn">
                                            <Link to="/module" className="default-btn">Lihat Kelas</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* About Area End */}

                    {/* Blog Area */}
                    <div className="blog-area pb-70">
                        <div className="container">
                            <div className="scetion-title text-center">
                                <span>Blogs</span>
                                <h2>{frontend.blog_text}</h2>
                            </div>
                            <div className="row pt-45">
                                <OwlCarousel options={options}>
                                    {blog === null ?
                                        <p>Loading...</p>
                                        :
                                        blog.map((news) => {
                                            return (
                                                <div className="blog-card" style={{ marginRight: '15px' }}>
                                                    <div className="blog-img">
                                                        <Link to={`blog/${news.id}`}>
                                                            <img src={news.image} alt="Blog Images" height={340} width={340} />
                                                        </Link>
                                                    </div>
                                                    <div className="blog-content">
                                                        <Link to={`blog/${news.id}`}>
                                                            <h3>{news.judul}</h3>
                                                        </Link>
                                                        <Link to={`blog/${news.id}`} className="read-btn">Read More</Link>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                    {/* Blog Area End */}

                    {/* Invitation */}
                    <div className="about-area about-max ptb-70 text-center">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-12">
                                    <div className="">
                                        <img src={frontend.future_logo} alt="logo" />
                                        <h2>{frontend.future_big_text}</h2>
                                        <p>
                                            {frontend.future_small_text}
                                        </p>
                                        <div className="about-btn">
                                            <a href="#" className="default-btn">Daftar Sekarang</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Invitation End */}

                    <Footer />
                </>
            }
        </React.Fragment>
    );
}

export default Home;