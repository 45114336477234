import React, { useState, useEffect } from 'react'

//import hook useHitory from react router dom
import { useHistory } from 'react-router'

//import axios
import axios from 'axios'
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'
import { HalfMalf } from 'react-spinner-animated';

const Profile = () => {
    const url = "https://lbw-edunesia.com/api-lbw/public/";
    //state user
    const [user, setUser] = useState({});
    const [fetch, setFetch] = useState(false);
    const [profile, setProfile] = useState(null);
    //define history
    const history = useHistory();

    //token
    const token = localStorage.getItem("token");
    // handle perubahan pada form
    function handleChange(e) {
        const { name, value } = e.target;

        setProfile(prevValue => {
            if (name === "hp_user") {
                return {
                    id_user: user.user.id,
                    hp_user: value,
                    pendidikan: prevValue.pendidikan,
                    nama_ortu: prevValue.nama_ortu,
                    email_ortu: prevValue.email_ortu,
                    hp_ortu: prevValue.hp_ortu,
                    alamat: prevValue.alamat,
                }
            } else if (name === "pendidikan") {
                return {
                    id_user: user.user.id,
                    hp_user: prevValue.hp_user,
                    pendidikan: value,
                    nama_ortu: prevValue.nama_ortu,
                    email_ortu: prevValue.email_ortu,
                    hp_ortu: prevValue.hp_ortu,
                    alamat: prevValue.alamat,
                }
            } else if (name === "nama_ortu") {
                return {
                    id_user: user.user.id,
                    hp_user: prevValue.hp_user,
                    pendidikan: prevValue.pendidikan,
                    nama_ortu: value,
                    email_ortu: prevValue.email_ortu,
                    hp_ortu: prevValue.hp_ortu,
                    alamat: prevValue.alamat,
                }
            } else if (name === "email_ortu") {
                return {
                    id_user: user.user.id,
                    hp_user: prevValue.hp_user,
                    pendidikan: prevValue.pendidikan,
                    nama_ortu: prevValue.nama_ortu,
                    email_ortu: value,
                    hp_ortu: prevValue.hp_ortu,
                    alamat: prevValue.alamat,
                }
            } else if (name === "hp_ortu") {
                return {
                    id_user: user.user.id,
                    hp_user: prevValue.hp_user,
                    pendidikan: prevValue.pendidikan,
                    nama_ortu: prevValue.nama_ortu,
                    email_ortu: prevValue.email_ortu,
                    hp_ortu: value,
                    alamat: prevValue.alamat,
                }
            } else if (name === "alamat") {
                return {
                    id_user: user.user.id,
                    hp_user: prevValue.hp_user,
                    pendidikan: prevValue.pendidikan,
                    nama_ortu: prevValue.nama_ortu,
                    email_ortu: prevValue.email_ortu,
                    hp_ortu: prevValue.hp_ortu,
                    alamat: value,
                }
            }
        })
    }
    //function "fetchData"
    const fetchData = async () => {

        //set axios header dengan type Authorization + Bearer token
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        //fetch user from Rest API
        await axios.get(url+'api/user')
            .then((response) => {
                //set response user to state
                if(response.data.status == "Token is Expired"){
                    localStorage.removeItem("token");
                    history.push('/login');
                }
                setUser(response.data);
                setFetch(true)

                axios.get(url+'api/profile?id_user=' + response.data.user.id)
                    .then((response) => {
                        //set response user to state
                        setProfile(response.data.data)
                        // setIsFetch(true)
                    }).catch(err => {
                        console.log(err)
                        setProfile({
                            id_user: "",
                            hp_user: "",
                            pendidikan: "",
                            nama_ortu: "",
                            email_ortu: "",
                            hp_ortu: "",
                            alamat: "",
                        })
                    })
            }).catch(err => console.log(err))
    }

    //hook useEffect
    useEffect(() => {
        //check token empty
        if (!localStorage.getItem("token")) {

            //redirect login page
            history.push('/');
        }

        //call function "fetchData"
        fetchData();
    }, []);

    async function updateProfile(e) {
        e.preventDefault();

        // post data
        await axios.post(url+'api/profile', profile)
            .then((response) => {
                console.log("sukses")
                history.push('/');
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <React.Fragment>
            {!fetch ? <HalfMalf text={"Loading..."} bgColor={"#f8f9fa"} center={true} width={"150px"} height={"150px"}/> :
                <>
                <Navbar />
                <section className="contact-section ptb-100">
                    <div className="container">
                        <div className="scetion-title text-center">
                            <span>Profile</span>
                            <h2>{user.user.name}</h2>

                        </div>
                        <div className="contact-wrap pt-45">
                            <div className="contact-wrap-form">
                                <div className="row">
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="form-group">
                                            <label>Nama</label>
                                            <input type="text" name="name" id="name" className="form-control" value={user.user.name} disabled />
                                        </div>
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input type="text" name="email" id="email" className="form-control" value={user.user.email} disabled />
                                        </div>
                                        <div className="form-group">
                                            <label>HP</label>
                                            <input type="text" name="hp_user" id="hp_user" className="form-control" value={profile && profile.hp_user} onChange={handleChange} />
                                        </div>
                                        <div className="form-group">
                                            <label>Pendidikan</label>
                                            <select name="pendidikan" id="pendidikan" className='form-control' onChange={handleChange}>
                                                {profile &&
                                                    <>
                                                        {profile.pendidikan === "" ? <option value="">Pilih Pendidikan</option> : <option value={profile.pendidikan}>{profile.pendidikan}</option>}
                                                        <option value="SD">SD</option>
                                                        <option value="SMP">SMP</option>
                                                        <option value="SMA">SMA</option>
                                                    </>
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="form-group">
                                            <label>Nama Orang tua</label>
                                            <input type="text" name="nama_ortu" id="namaOrtu" className="form-control" value={profile && profile.nama_ortu} onChange={handleChange} />
                                        </div>
                                        <div className="form-group">
                                            <label>Email Orang tua</label>
                                            <input type="text" name="email_ortu" id="emailOrtu" className="form-control" value={profile && profile.email_ortu} onChange={handleChange} />
                                        </div>
                                        <div className="form-group">
                                            <label>HP Orang tua</label>
                                            <input type="text" name="hp_ortu" id="hp_ortu" className="form-control" value={profile && profile.hp_ortu} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label>Alamat</label>
                                            {profile &&
                                                <textarea name="alamat" class="form-control" id="alamat" cols="30" rows="3" onChange={handleChange}>{profile.alamat}</textarea>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <button style={{width: '20%'}} className='btn btn-primary ml-3 default-btn' onClick={updateProfile}>Update Profile</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                </>
            }
        </React.Fragment>
    );
}

export default Profile;