import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { useHistory } from 'react-router';
import { HalfMalf }
    from 'react-spinner-animated';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";

const HistoryQuiz = () => {
    const url = "https://lbw-edunesia.com/api-lbw/public/";
    // const url = "http://localhost:8000/";
    const [historyQuiz, setHistoryQuiz] = useState([]);
    const [isPending, setIsPending] = useState(true);
    const { id } = useParams();
    const history = useHistory();

    useEffect(() => {
        if (!localStorage.getItem("token")) {
            history.push('/');
        }

        axios.get(url + 'api/history-quiz?id=' + id)
            .then((response) => {
                //set response user to state
                setHistoryQuiz(response.data.data)
                setIsPending(false);
            }).catch(err => console.log(err))

    }, [setIsPending])

    return (
        <React.Fragment>
            {isPending &&
                <HalfMalf text={"Loading..."} bgColor={"#f8f9fa"} center={true} width={"150px"} height={"150px"} />
            }
            {!isPending &&
                <div className="container">
                    <div className="mt-3">
                        <div className="row">
                            <div className="col-md-2">
                                <Link to={`/kelas/materi/quiz/${historyQuiz.id_mapel}`} className="pt-2 text-sm btn bg-gradient-dark"><FontAwesomeIcon icon={faArrowAltCircleLeft} /></Link>
                            </div>
                            <div className="col-md-10">
                                <h1
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        marginBottom: 10,
                                        marginLeft: '-150px',
                                    }}
                                >
                                    Quiz Score:{" "}
                                    <div className="text-success">{historyQuiz.nilai}</div>
                                </h1>
                            </div>
                        </div>
                        {historyQuiz.history_data.map((item, index) => (
                            <div className="card mb-3" key={index}>
                                <div className="card-header bg-white">
                                    <div className="font-weight-bold">No. {index + 1}</div>{" "}
                                    {item.soal_data.soal}
                                </div>
                                <div className="card-body">
                                    {/* A */}
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyItems: "center",
                                            alignItems: "center",
                                            fontSize: 18,
                                        }}
                                        key={item.soal_data.id}
                                    >
                                        <div
                                            style={{
                                                height: 20,
                                                width: 20,
                                                borderRadius: 100,
                                                backgroundColor: item.answer == "A" ? "greenyellow" : "grey",
                                                cursor: "pointer",
                                                marginRight: 5,
                                            }}
                                        />
                                        A. {item.soal_data.a}
                                    </div>
                                    {/* B */}
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyItems: "center",
                                            alignItems: "center",
                                            fontSize: 18,
                                        }}
                                        key={item.soal_data.id}
                                    >
                                        <div
                                            style={{
                                                height: 20,
                                                width: 20,
                                                borderRadius: 100,
                                                backgroundColor: item.answer == "B" ? "greenyellow" : "grey",
                                                cursor: "pointer",
                                                marginRight: 5,
                                            }}
                                        />
                                        B. {item.soal_data.b}
                                    </div>
                                    {/* C */}
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyItems: "center",
                                            alignItems: "center",
                                            fontSize: 18,
                                        }}
                                        key={item.soal_data.id}
                                    >
                                        <div
                                            style={{
                                                height: 20,
                                                width: 20,
                                                borderRadius: 100,
                                                backgroundColor: item.answer == "C" ? "greenyellow" : "grey",
                                                cursor: "pointer",
                                                marginRight: 5,
                                            }}
                                        />
                                        C. {item.soal_data.c}
                                    </div>
                                    {/* D */}
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyItems: "center",
                                            alignItems: "center",
                                            fontSize: 18,
                                        }}
                                        key={item.soal_data.id}
                                    >
                                        <div
                                            style={{
                                                height: 20,
                                                width: 20,
                                                borderRadius: 100,
                                                backgroundColor: item.answer == "D" ? "greenyellow" : "grey",
                                                cursor: "pointer",
                                                marginRight: 5,
                                            }}
                                        />
                                        D. {item.soal_data.d}
                                    </div>
                                    {/* E */}
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyItems: "center",
                                            alignItems: "center",
                                            fontSize: 18,
                                        }}
                                        key={item.soal_data.id}
                                    >
                                        <div
                                            style={{
                                                height: 20,
                                                width: 20,
                                                borderRadius: 100,
                                                backgroundColor: item.answer == "E" ? "greenyellow" : "grey",
                                                cursor: "pointer",
                                                marginRight: 5,
                                            }}
                                        />
                                        E. {item.soal_data.e}
                                    </div>
                                </div>
                                <div className="card-footer bg-white">
                                    {item.answer == item.soal_data.jawaban ?
                                        <div className="text-success">
                                            Your Answer: {item.answer}
                                        </div>
                                        :
                                        <>
                                            <div className="text-danger">
                                                Your Answer :{" "}
                                                {item.answer ??
                                                    "You don't answer this question"}
                                            </div>
                                            <div className="text-success">
                                                Correct Answer :{" "}
                                                {item.soal_data.jawaban}
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            }
        </React.Fragment>
    );
}

export default HistoryQuiz;