import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from 'axios';
import { useHistory } from 'react-router';
import Navbar2 from "../../components/Navbar2"
import Logo from '../../lbw-logo.png'
import { HalfMalf }
    from 'react-spinner-animated';

const KelasDetail = () => {
    const url = "https://lbw-edunesia.com/api-lbw/public/";
    // const url = "http://localhost:8000/";
    const [module, setModule] = useState([]);
    const [isPending, setIsPending] = useState(true);
    const { id } = useParams();
    const [kelas, setKelas] = useState([])
    const history = useHistory();
    const [auth, setAuth] = useState([]);

    useEffect(() => {
        if (!localStorage.getItem("token")) {
            history.push('/');
        }
        // fetch module from Rest API
        axios.get(url + 'api/module?id=' + id)
            .then((response) => {
                //set response user to state
                setModule(response.data.data)
                // setIsFetch(true)
            }).catch(err => console.log(err))

        // set header
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`
        //fetch user from Rest API
        axios.get(url + 'api/user')
            .then((response) => {
                //set response user to state
                if (response.data.status == "Token is Expired") {
                    localStorage.removeItem("token");
                    history.push('/login');
                }
                setAuth(response.data.user);
                //fetch transaction from Rest API
                axios.get(url + 'api/subscription?id_user=' + response.data.user.id)
                    .then((response) => {
                        //set response user to state
                        setKelas(response.data.data)
                        response.data.data.forEach(sub => {
                            if (sub.id_module == id) {
                                const current = new Date(sub.tanggal)
                                const current2 = new Date()
                                let Difference_In_Time = current2.getTime() - current.getTime();
                                let Difference_In_Days = Math.floor(Difference_In_Time / (1000 * 3600 * 24));
                                let bulan = 365 / 12;
                                let masa_aktif = Math.ceil((sub.durasi * bulan) - Difference_In_Days);
                                if (masa_aktif < 0) {
                                    history.push('/module/' + id);
                                }
                            }
                        });
                        setIsPending(false)
                    }).catch(err => console.log(err))
            }).catch(err => console.log(err))
    }, [setIsPending])

    return (
        <React.Fragment>

            {isPending &&
                <HalfMalf text={"Loading..."} bgColor={"#f8f9fa"} center={true} width={"150px"} height={"150px"} />
            }
            {!isPending &&
                <><React.Fragment>
                    <aside
                        className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 "
                        id="sidenav-main"
                    >
                        <div className="sidenav-header">
                            <i
                                className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
                                aria-hidden="true"
                                id="iconSidenav"
                            />
                            <Link
                                className="navbar-brand m-0"
                                to="/"
                                target="_blank"
                            >
                                <img
                                    src={Logo}
                                    className="navbar-brand-img h-100"
                                    alt="main_logo"
                                />
                                <span className="ms-1 font-weight-bold">Little Bookworm</span>
                            </Link>
                        </div>
                        <hr className="horizontal dark mt-0" />
                        <div
                            className="collapse navbar-collapse  w-auto  max-height-vh-100 h-100"
                            id="sidenav-collapse-main"
                        >
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a className="nav-link  active">
                                        <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                            <svg
                                                width="12px"
                                                height="12px"
                                                viewBox="0 0 45 40"
                                                version="1.1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                            >
                                                <title>shop </title>
                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                    <g
                                                        transform="translate(-1716.000000, -439.000000)"
                                                        fill="#FFFFFF"
                                                        fillRule="nonzero"
                                                    >
                                                        <g transform="translate(1716.000000, 291.000000)">
                                                            <g transform="translate(0.000000, 148.000000)">
                                                                <path
                                                                    className="color-background opacity-6"
                                                                    d="M46.7199583,10.7414583 L40.8449583,0.949791667 C40.4909749,0.360605034 39.8540131,0 39.1666667,0 L7.83333333,0 C7.1459869,0 6.50902508,0.360605034 6.15504167,0.949791667 L0.280041667,10.7414583 C0.0969176761,11.0460037 -1.23209662e-05,11.3946378 -1.23209662e-05,11.75 C-0.00758042603,16.0663731 3.48367543,19.5725301 7.80004167,19.5833333 L7.81570833,19.5833333 C9.75003686,19.5882688 11.6168794,18.8726691 13.0522917,17.5760417 C16.0171492,20.2556967 20.5292675,20.2556967 23.494125,17.5760417 C26.4604562,20.2616016 30.9794188,20.2616016 33.94575,17.5760417 C36.2421905,19.6477597 39.5441143,20.1708521 42.3684437,18.9103691 C45.1927731,17.649886 47.0084685,14.8428276 47.0000295,11.75 C47.0000295,11.3946378 46.9030823,11.0460037 46.7199583,10.7414583 Z"
                                                                />
                                                                <path
                                                                    className="color-background"
                                                                    d="M39.198,22.4912623 C37.3776246,22.4928106 35.5817531,22.0149171 33.951625,21.0951667 L33.92225,21.1107282 C31.1430221,22.6838032 27.9255001,22.9318916 24.9844167,21.7998837 C24.4750389,21.605469 23.9777983,21.3722567 23.4960833,21.1018359 L23.4745417,21.1129513 C20.6961809,22.6871153 17.4786145,22.9344611 14.5386667,21.7998837 C14.029926,21.6054643 13.533337,21.3722507 13.0522917,21.1018359 C11.4250962,22.0190609 9.63246555,22.4947009 7.81570833,22.4912623 C7.16510551,22.4842162 6.51607673,22.4173045 5.875,22.2911849 L5.875,44.7220845 C5.875,45.9498589 6.7517757,46.9451667 7.83333333,46.9451667 L19.5833333,46.9451667 L19.5833333,33.6066734 L27.4166667,33.6066734 L27.4166667,46.9451667 L39.1666667,46.9451667 C40.2482243,46.9451667 41.125,45.9498589 41.125,44.7220845 L41.125,22.2822926 C40.4887822,22.4116582 39.8442868,22.4815492 39.198,22.4912623 Z"
                                                                />
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        <span className="nav-link-text ms-1">Kelas Saya</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                </React.Fragment>
                    <main class="main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg ">
                        <Navbar2 />
                        <div>
                            <h4>Mata Pelajaran</h4>
                            <div className="container">
                                <div className="row mt-3">
                                    {module.mapel_module_data.map(mapel => {
                                        return (
                                            <div className="col-md-3 mb-4" key={mapel.id}>
                                                <div className="card" style={{ borderRadius: '25px' }}>
                                                    <div className="card-header mx-4 p-3 text-center">
                                                        <div className="icon icon-shape icon-lg bg-gradient-primary shadow text-center border-radius-lg">
                                                            <i className="fas fa-book-open opacity-10" />
                                                        </div>
                                                    </div>
                                                    <div className="card-body pt-0 p-3 text-center">
                                                        <h6 className="text-center mb-0">{mapel.mapel_data.nama}</h6>
                                                        <hr className="horizontal dark my-3" />
                                                        <Link class="btn bg-gradient-dark mb-0" to={`materi/${mapel.mapel_data.id}`}>Belajar</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            }

        </React.Fragment>
    );
}

export default KelasDetail;